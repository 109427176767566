import React, {useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet-async";
import { ITEMS_MASTER_TRANSTYPE} from "../../../routes/ConfigRoutes";
import {useStylesContext} from "../../../context/StylesContext";
import {
    Button,
    Checkbox,
    Col,
    Flex,
    Input,
    InputRef,
    message,
    Modal,
    Row,
    Space,
    Table,
    TableColumnsType,
    Tooltip
} from "antd";
import {useNavigate} from "react-router-dom";
import {Card} from "../../../components/Card/Card";
import {PageHeader} from "../../../components/PageHeader/PageHeader";
import {DeleteOutlined, EditOutlined, PlusOutlined, ProjectOutlined, SearchOutlined} from "@ant-design/icons";
import {FlexCustom} from "../../../components/Flex/FlexCustom";
import {GetStyle, PostStyle} from "../../../service/UserStyleServices";
import {ColumnType} from "antd/es/table";
import {FilterConfirmProps} from "antd/es/table/interface";
import {useTranslation} from "react-i18next";
import {AppName} from "../../../config/Config";
import {StatusCode} from "../../../service/AppService";
import {DeleteTransType, GetListTransType} from "../../../service/master/TransTypeServices";
import CheckAcess from "../../../routes/Helper/CheckAcess";

interface DataType {
    transTypeId:number
    transType:string
    moduleType:string
    indonesiaName:string
    khmerName:string
    vietnamName:string
    crdb:string
    description:string
    seq:number
    isActive:boolean
    createdBy:string
    createdDate:string
    modifiedBy:string
    modifiedDate:string
}

const styleName = "TransTypeColumns";
const excludedKeys = ['createdBy', 'createdDate', 'modifiedBy', 'modifiedDate'];

const TransType = () => {
    const { t } = useTranslation();
    const stylesContext = useStylesContext();
    const navigate = useNavigate();
    const [openModalColumn, setOpenModalColumn] = useState(false);

    const [searchedColumn, setSearchedColumn] = useState<DataType>();
    const searchInput = useRef<InputRef>(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState<DataType[]>([]);
    type DataIndex = keyof DataType;

    const handleSearchs = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchedColumn(dataIndex as any);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
    };
    const getColumnSearchProps = (dataIndex: keyof DataType): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={t('SearchField', {field: String(dataIndex)})}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearchs(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearchs(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                        {t('Search')}
                    </Button>
                    <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('Reset')}
                    </Button>
                    <Button type="link" size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchedColumn(dataIndex as any);
                        }} >
                        Filter
                    </Button>
                    <Button type="link" size="small"
                        onClick={() => {
                            close();
                        }}>
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            String(record[dataIndex])
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible && searchInput.current) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex as any ? (
                <span>
          {text}
        </span>
            ) : (
                text
            ),
    });
    const columns: TableColumnsType<DataType> = [
        // {title: 'Trans Type Id', dataIndex: 'transTypeId', key: 'transTypeId', sorter:  (a, b) => a.transTypeId - b.transTypeId, ellipsis: true, ...getColumnSearchProps('transTypeId')},
        {title: 'Trans Type', dataIndex: 'transType', key: 'transType', fixed: 'left', sorter: (a, b) => a.transType.localeCompare(b.transType), ellipsis: true, ...getColumnSearchProps('transType')},
        {title: 'Module Type', dataIndex: 'moduleType', key: 'moduleType',  sorter: (a, b) => a.moduleType.localeCompare(b.moduleType), ellipsis: true, ...getColumnSearchProps('moduleType')},
        {title: 'Indonesia Name', dataIndex: 'indonesiaName', key: 'indonesiaName', sorter: (a, b) => a.indonesiaName.localeCompare(b.indonesiaName), ellipsis: true, ...getColumnSearchProps('indonesiaName')},
        {title: 'Khmer Name', dataIndex: 'khmerName', key: 'khmerName', sorter: (a, b) => a.khmerName.localeCompare(b.khmerName), ellipsis: true, ...getColumnSearchProps('khmerName')},
        {title: 'Vietnam Name', dataIndex: 'vietnamName', key: 'vietnamName', sorter: (a, b) => a.vietnamName.localeCompare(b.vietnamName), ellipsis: true, ...getColumnSearchProps('vietnamName')},
        {title: 'Crdb', dataIndex: 'crdb', key: 'crdb', sorter: (a, b) => a.crdb.localeCompare(b.crdb), ellipsis: true, ...getColumnSearchProps('crdb')},
        {title: 'Description', dataIndex: 'description', key: 'description', sorter: (a, b) => a.description.localeCompare(b.description), ellipsis: true, ...getColumnSearchProps('description')},
        {title: 'Seq', dataIndex: 'seq', key: 'seq', sorter:  (a, b) => a.seq - b.seq, ellipsis: true, ...getColumnSearchProps('seq')},
        {title: 'IsActive', dataIndex: 'isActive', key: 'isActive', sorter: (a, b) => (a.isActive === b.isActive) ? 0 : a.isActive ? -1 : 10, ellipsis: true, ...getColumnSearchProps('isActive')},
        {title: 'Created By', dataIndex: 'createdBy', key: 'createdBy', sorter: (a, b) => a.createdBy.localeCompare(b.createdBy), ellipsis: true, ...getColumnSearchProps('createdBy')},
        {title: 'Created Date', dataIndex: 'createdDate', key: 'createdDate', sorter: (a, b) => a.createdDate.localeCompare(b.createdDate), ellipsis: true, ...getColumnSearchProps('createdDate')},
        {title: 'Modified By', dataIndex: 'modifiedBy', key: 'modifiedBy', sorter: (a, b) => a.modifiedBy.localeCompare(b.modifiedBy), ellipsis: true, ...getColumnSearchProps('modifiedBy')},
        {title: 'Modified Date', dataIndex: 'modifiedDate', key: 'modifiedDate', sorter: (a, b) => a.modifiedDate.localeCompare(b.modifiedDate), ellipsis: true, ...getColumnSearchProps('modifiedDate')},
        {
            title: 'Actions',
            dataIndex: 'actions',
            width: 100,
            key: 'actions',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip title={t("Edit Record")}>
                        <Button onClick={() => handleEdit(record.transTypeId)} shape="circle"><EditOutlined /></Button>
                    </Tooltip>
                    <Tooltip title={t("Delete Record")}>
                        <Button onClick={() => handleDelete(record.transTypeId, record.transType)} shape="circle" type='primary' danger><DeleteOutlined /></Button>
                    </Tooltip>
                </Space>
            ),
        },
    ];
    const handleEdit = (transTypeId:number) => {
        // Logika untuk meng-handle edit
        if(CheckAcess('Member.New')) navigate(`/master/trans_type/${transTypeId}`);
    };
    const handleDelete = (transTypeId:number, transType:string) => {
        // Logika untuk meng-handle delete

        Modal.confirm({
            type: 'warning',
            title: t('Warning'),
            content: t('Are you sure want to delete', {field:transType}),
            cancelText: t('Cancel'),
            onOk() {
                DeleteData(transTypeId);
            },
            onCancel() {
                message.open({type: 'info', content: t('Cancel Delete')});
            },
        });
    };

    const ReloadList = async () => {
        try {
            setTableLoading(true);
            await ReloadStyle();
            const result = await GetListTransType();
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                setDataSource(result.data);
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setTableLoading(false);
        }
    };
    const HandleDeleteRow = (transTypeId:number) => {
        const newData = dataSource.filter((item) => item.transTypeId !== transTypeId);
        setDataSource(newData);
    };
    const DeleteData = async (transTypeId:number) => {
        try {
            setTableLoading(true);
            const result = await DeleteTransType(transTypeId);
            if(result.status === StatusCode.Code200_OK && result.success && result.message !== undefined) {
                setDataSource(result.data);
                HandleDeleteRow(transTypeId);
                message.open({type: 'success', content: result.message});
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setTableLoading(false);
        }
    };

    useEffect(() => {
        ReloadList();
    }, []);


    const defaultCheckedList = columns.map((item) => item.key as string).filter((key) => !excludedKeys.includes(key));
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const newColumns = columns.map((item) => ({
        ...item,
        hidden: !checkedList.includes(item.key as string),
    }));
    const SaveStyle = async () => {
        try {
            const result = await PostStyle(styleName, checkedList);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                message.open({type: 'success', content: result.message});
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
        }
    }
    const ChangedCheckedList = (value:string[]) => {
        setCheckedList(value);
    }
    const ReloadStyle = async () => {
        try {
            const result = await GetStyle(styleName);
            if(result.status === StatusCode.Code200_OK && result.success && result.data.length !> 0) {
                setCheckedList(result.data);
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
        }
    }
    // useEffect(() => {
    //     ReloadStyle();
    // }, [columns]);
    return (
    <div>
        <Helmet>
        <title>Trans Type | {AppName}</title>
        </Helmet>
            <Flex vertical gap="middle">
                <PageHeader title="Trans Type" breadcrumbs={ITEMS_MASTER_TRANSTYPE}/>
                <Row {...stylesContext?.rowProps}>
                    <Col xs={24} lg={24}>
                        <Card>
                            <Flex vertical gap="small">
                                <Row>
                                    <Col xs={24} lg={24}>
                                        <FlexCustom justifyContent="end">
                                                <Tooltip title={t("New Record")}>
                                                    <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={()=>navigate('/master/trans_type/new')} />
                                                </Tooltip>
                                            <Tooltip title={t("Columns")}>
                                                <Button type="primary" shape="circle" icon={<ProjectOutlined />} onClick={()=>setOpenModalColumn(true)} />
                                            </Tooltip>
                                        </FlexCustom>
                                    </Col>
                                </Row>
                                <Table rowKey="transTypeId"
                                    columns={newColumns}
                                    dataSource={dataSource}
                                    loading={tableLoading}
                                    scroll={{ x: true }}
                                    size="small" />
                                <Modal
                                    title={<p>List Columns</p>}
                                    footer={
                                        <Button type="primary" onClick={SaveStyle}>
                                            Save Template
                                        </Button>
                                    }
                                    open={openModalColumn}
                                    onCancel={()=>setOpenModalColumn(false)}
                                >
                                    <Checkbox.Group
                                        value={checkedList}
                                        onChange={(value) => {
                                            ChangedCheckedList(value as string[]);
                                        }}
                                    >
                                        <Row>
                                            {columns.map(option => (
                                                <Col xs={12} lg={8} key={option.title as string}>
                                                    <Checkbox value={option.key} disabled={option.title==='Actions'}>{option.title as string}</Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Modal>
                            </Flex>
                        </Card>
                    </Col>
                </Row>
            </Flex>
    </div>
  );
};

export default TransType;
