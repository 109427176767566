import React, {useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet-async";
import {ITEMS_MASTER_MEMBER} from "../../../routes/ConfigRoutes";
import {useStylesContext} from "../../../context/StylesContext";
import {
    Button,
    Checkbox,
    Col,
    Flex,
    Input,
    InputRef,
    message,
    Modal,
    Row,
    Space,
    Table,
    TableColumnsType,
    Tooltip
} from "antd";
import {useNavigate} from "react-router-dom";
import {Card} from "../../../components/Card/Card";
import {PageHeader} from "../../../components/PageHeader/PageHeader";
import {DeleteOutlined, EditOutlined, PlusOutlined, ProjectOutlined, SearchOutlined} from "@ant-design/icons";
import {FlexCustom} from "../../../components/Flex/FlexCustom";
import {GetStyle, PostStyle} from "../../../service/UserStyleServices";
import {ColumnType} from "antd/es/table";
import {FilterConfirmProps, TableRowSelection} from "antd/es/table/interface";
import {useTranslation} from "react-i18next";
import {AppName} from "../../../config/Config";
import {StatusCode} from "../../../service/AppService";
import {DeleteMember, GetListMember} from "../../../service/master/MemberServices";
import CheckAcess from "../../../routes/Helper/CheckAcess";

interface DataType {
    memberId:number
    userId:string
    email:string
    telp:string
    bank:string
    accountNumber:string
    accountName:string
    isActive:boolean
    createdBy:string
    createdDate:string
    modifiedBy:string
    modifiedDate:string
}

const styleName = "MemberColumns";
const excludedKeys = ['createdBy', 'createdDate', 'modifiedBy', 'modifiedDate'];

const Member = () => {
    const { t } = useTranslation();
    const stylesContext = useStylesContext();
    const navigate = useNavigate();
    const [openModalColumn, setOpenModalColumn] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [searchedColumn, setSearchedColumn] = useState<DataType>();
    const searchInput = useRef<InputRef>(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState<DataType[]>([]);
    type DataIndex = keyof DataType;

    const rowSelection: TableRowSelection<DataType> = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };

    const handleSearchs = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchedColumn(dataIndex as any);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
    };
    const getColumnSearchProps = (dataIndex: keyof DataType): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={t('SearchField', {field: String(dataIndex)})}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearchs(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearchs(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                        {t('Search')}
                    </Button>
                    <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('Reset')}
                    </Button>
                    <Button type="link" size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchedColumn(dataIndex as any);
                        }} >
                        Filter
                    </Button>
                    <Button type="link" size="small"
                        onClick={() => {
                            close();
                        }}>
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            String(record[dataIndex])
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible && searchInput.current) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex as any ? (
                <span>
          {text}
        </span>
            ) : (
                text
            ),
    });
    const columns: TableColumnsType<DataType> = [
        // {title: 'Member Id', dataIndex: 'memberId', key: 'memberId', sorter:  (a, b) => a.memberId - b.memberId, ellipsis: true, ...getColumnSearchProps('memberId')},
        {title: 'User Id', dataIndex: 'userId', key: 'userId', fixed:'left', sorter: (a, b) => a.userId.localeCompare(b.userId), ellipsis: true, ...getColumnSearchProps('userId')},
        {title: 'Email', dataIndex: 'email', key: 'email', sorter: (a, b) => a.email.localeCompare(b.email), ellipsis: true, ...getColumnSearchProps('email')},
        {title: 'Telp', dataIndex: 'telp', key: 'telp', sorter: (a, b) => a.telp.localeCompare(b.telp), ellipsis: true, ...getColumnSearchProps('telp')},
        {title: 'Bank', dataIndex: 'bank', key: 'bank', sorter: (a, b) => a.bank.localeCompare(b.bank), ellipsis: true, ...getColumnSearchProps('bank')},
        {title: 'Account Number', dataIndex: 'accountNumber', key: 'accountNumber', sorter: (a, b) => a.accountNumber.localeCompare(b.accountNumber), ellipsis: true, ...getColumnSearchProps('accountNumber')},
        {title: 'Account Name', dataIndex: 'accountName', key: 'accountName', sorter: (a, b) => a.accountName.localeCompare(b.accountName), ellipsis: true, ...getColumnSearchProps('accountName')},
        {title: 'Is Active', dataIndex: 'isActive', key: 'isActive', sorter: (a, b) => (a.isActive === b.isActive) ? 0 : a.isActive ? -1 : 10, ellipsis: true, ...getColumnSearchProps('isActive')},
        {title: 'Created By', dataIndex: 'createdBy', key: 'createdBy', sorter: (a, b) => a.createdBy.localeCompare(b.createdBy), ellipsis: true, ...getColumnSearchProps('createdBy')},
        {title: 'Created Date', dataIndex: 'createdDate', key: 'createdDate', sorter: (a, b) => a.createdDate.localeCompare(b.createdDate), ellipsis: true, ...getColumnSearchProps('createdDate')},
        {title: 'Modified By', dataIndex: 'modifiedBy', key: 'modifiedBy', sorter: (a, b) => a.modifiedBy.localeCompare(b.modifiedBy), ellipsis: true, ...getColumnSearchProps('modifiedBy')},
        {title: 'Modified Date', dataIndex: 'modifiedDate', key: 'modifiedDate', sorter: (a, b) => a.modifiedDate.localeCompare(b.modifiedDate), ellipsis: true, ...getColumnSearchProps('modifiedDate')},
        {
            title: 'Actions',
            dataIndex: 'actions',
            width: 100,
            key: 'actions',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip title={t("Edit Record")}>
                        <Button onClick={() => handleEdit(record.memberId)} shape="circle"><EditOutlined /></Button>
                    </Tooltip>
                    <Tooltip title={t("Delete Record")}>
                        <Button onClick={() => handleDelete(record.memberId, record.userId)} shape="circle" type='primary' danger><DeleteOutlined /></Button>
                    </Tooltip>
                </Space>
            ),
        },
    ];
    const handleEdit = (memberId:number) => {
        // Logika untuk meng-handle edit
        if(CheckAcess('Member.View')) navigate(`/master/member/${memberId}`);
    };
    const handleNew = () => {
        // Logika untuk meng-handle edit
        if(CheckAcess('Member.New')) navigate('/master/member/new');
    };
    const handleDelete = (memberId:number, userId:string) => {
        // Logika untuk meng-handle delete
        if(!CheckAcess('Member.Delete')) return;
        Modal.confirm({
            type: 'warning',
            title: t('Warning'),
            content: t('Are you sure want to delete', {field:userId}),
            cancelText: t('Cancel'),
            onOk() {
                DeleteData(memberId);
            },
            onCancel() {
                message.open({type: 'info', content: t('Cancel Delete')});
            },
        });
    };

    const ReloadList = async () => {
        try {
            setTableLoading(true);
            await ReloadStyle();
            const result = await GetListMember();
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                setDataSource(result.data);
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setTableLoading(false);
        }
    };
    const HandleDeleteRow = (memberId:number) => {
        const newData = dataSource.filter((item) => item.memberId !== memberId);
        setDataSource(newData);
    };
    const DeleteData = async (memberId:number) => {
        try {
            setTableLoading(true);
            const result = await DeleteMember(memberId);
            if(result.status === StatusCode.Code200_OK && result.success && result.message !== undefined) {
                setDataSource(result.data);
                HandleDeleteRow(memberId);
                message.open({type: 'success', content: result.message});
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setTableLoading(false);
        }
    };

    useEffect(() => {
        ReloadList();
    }, []);


    const defaultCheckedList = columns.map((item) => item.key as string).filter((key) => !excludedKeys.includes(key));
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const newColumns = columns.map((item) => ({
        ...item,
        hidden: !checkedList.includes(item.key as string),
    }));
    const SaveStyle = async () => {
        try {
            const result = await PostStyle(styleName, checkedList);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                message.open({type: 'success', content: result.message});
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
        }
    }
    const ChangedCheckedList = (value:string[]) => {
        setCheckedList(value);
    }
    const ReloadStyle = async () => {
        try {
            const result = await GetStyle(styleName);
            if(result.status === StatusCode.Code200_OK && result.success && result.data.length !> 0) {
                setCheckedList(result.data);
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
        }
    }
    // useEffect(() => {
    //     ReloadStyle();
    // }, [columns]);
    return (
    <div>
        <Helmet>
        <title>Member | {AppName}</title>
        </Helmet>
            <Flex vertical gap="middle">
                <PageHeader title="Member" breadcrumbs={ITEMS_MASTER_MEMBER}/>
                <Row {...stylesContext?.rowProps}>
                    <Col xs={24} lg={24}>
                        <Card>
                            <Flex vertical gap="small">
                                <Row>
                                    <Col xs={24} lg={24}>
                                        <FlexCustom justifyContent="end">
                                                <Tooltip title={t("New Record")}>
                                                    <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={handleNew} />
                                                </Tooltip>
                                            <Tooltip title={t("Columns")}>
                                                <Button type="primary" shape="circle" icon={<ProjectOutlined />} onClick={()=>setOpenModalColumn(true)} />
                                            </Tooltip>
                                        </FlexCustom>
                                    </Col>
                                </Row>
                                <Table rowKey="memberId"
                                    columns={newColumns}
                                    dataSource={dataSource}
                                    loading={tableLoading}
                                    scroll={{ x: true }}
                                    size="small" />
                                <Modal
                                    title={<p>List Columns</p>}
                                    footer={
                                        <Button type="primary" onClick={SaveStyle}>
                                            Save Template
                                        </Button>
                                    }
                                    open={openModalColumn}
                                    onCancel={()=>setOpenModalColumn(false)}
                                >
                                    <Checkbox.Group
                                        value={checkedList}
                                        onChange={(value) => {
                                            ChangedCheckedList(value as string[]);
                                        }}
                                    >
                                        <Row>
                                            {columns.map(option => (
                                                <Col xs={12} lg={8} key={option.title as string}>
                                                    <Checkbox value={option.key} disabled={option.title==='Actions'}>{option.title as string}</Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Modal>
                            </Flex>
                        </Card>
                    </Col>
                </Row>
            </Flex>
    </div>
  );
};

export default Member;
