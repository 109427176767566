import { Button, Flex, Typography } from 'antd';
import { Link } from 'react-router-dom';
import {AppName} from "../../config/Config";
import {PATH_HOME} from "../../routes/RoutePathList";
import {Logo} from "../../components/Logo/Logo";
import {useTranslation} from "react-i18next";

export const WelcomePage = () => {
  const { t } = useTranslation();
  return (
    <Flex
      vertical
      gap="large"
      align="center"
      justify="center"
      style={{ height: '80vh' }}
    >
      <Logo color="black" />
      <Typography.Title className="m-0">Welcome to {AppName}</Typography.Title>
      <Typography.Text style={{ fontSize: 18 }}>
        {t('You already Loggedin')}
      </Typography.Text>
      <Link to={PATH_HOME.path}>
        <Button type="primary" size="middle">
          {t('Go to', {page: 'Homepage'})}
        </Button>
      </Link>
    </Flex>
  );
};
