import { Result } from 'antd';
import {BackBtn} from "../../components/BackBtn/BackBtn";
import {RefreshBtn} from "../../components/RefreshBtn/RefreshBtn";
import {useTranslation} from "react-i18next";

export const Error403Page = () => {
  const { t } = useTranslation();
  return (
    <Result
      status="403"
      title="403"
      subTitle={t("Sorry, you are not authorized to access this page.")}
      extra={<BackBtn type="primary" />}
    >
    </Result>
  );
};
