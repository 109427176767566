import React, {useEffect,  useState} from "react";
import {Helmet} from "react-helmet-async";
import {ITEMS_SETTING_USER} from "../../../routes/ConfigRoutes";
import {useStylesContext} from "../../../context/StylesContext";
import {Button, Col, Flex, Form, Input, message, Row, Select, Switch} from "antd";
import {useParams} from "react-router-dom";
import {Card} from "../../../components/Card/Card";
import {PageHeader} from "../../../components/PageHeader/PageHeader";
import {useTranslation} from "react-i18next";
import {StatusCode} from "../../../service/AppService";
import {AppName} from "../../../config/Config";
import {IRoleListType, IUserFormType} from "../../../types/UserType";
import {GetDetailUser, SaveUser} from "../../../service/UserServices";
import CheckAcess from "../../../routes/Helper/CheckAcess";

const UserEditor = () => {
    const { t } = useTranslation();
    const { username } = useParams<{ username: string }>();
    const stylesContext = useStylesContext();
    const [user, setUser] = useState(username??'');
    const [loading, setLoading] = useState(false);
    const [roleList, setRoleList] = useState([] as IRoleListType[])
    const [form] = Form.useForm<IUserFormType>()
    const { Option } = Select;
    const title = () => {
        return user === '' ? t('NewFor', {field:'User'}) : t(`ViewFor`, {field: 'User',data: user});
    }
    const onFinish = async (values: IUserFormType) => {
        try {
            setLoading(true);
            if(username != '') if(!CheckAcess('User.Edit')) return;
            else if(username == '') if(!CheckAcess('User.New')) return;
            const result = await SaveUser(user ?? '', values);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                setUser(values.username);
                message.open({type: 'success', content: result.message});
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const ReloadData = async () => {
        try {
            setLoading(true);
            const result = await GetDetailUser(user);
            if(result.status === StatusCode.Code200_OK && result.success && result.data.length !== 0) {
                const data = result.data;

                if(data.data !== undefined) {
                    form.setFieldsValue({
                        username: data.data.username,
                        email: data.data.email,
                        fullname: data.data.fullname,
                        password: data.data.password,
                        role: data.data.role,
                        isActive: data.data.isActive,
                    });
                }

                setRoleList(data.roleList);
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };

    const ValidatePassword = (_: any, value: string) => {

        if(username === "") {
            if (!value) {
                return Promise.reject(new Error(t('Password is required')));
            }
            if (value.length < 8) {
                return Promise.reject(new Error(t('Password must be at least 8 characters long')));
            }
            if (!/[A-Z]/.test(value)) {
                return Promise.reject(new Error(t('Password must contain at least one uppercase letter')));
            }
            if (!/[a-z]/.test(value)) {
                return Promise.reject(new Error(t('Password must contain at least one lowercase letter')));
            }
            if (!/[0-9]/.test(value)) {
                return Promise.reject(new Error(t('Password must contain at least one number')));
            }
            if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
                return Promise.reject(new Error(t('Password must contain at least one special character')));
            }
        }
        return Promise.resolve();
    };

    useEffect(() => {
        ReloadData();
    }, []);



  return (
    <div>
      <Helmet>
        <title>Role |  {AppName}</title>
      </Helmet>
        <Flex vertical gap="middle">
            <PageHeader title="User" breadcrumbs={ITEMS_SETTING_USER}/>
            <Row {...stylesContext?.rowProps}>
                <Col xs={24} lg={24}>
                    <Card title={title()}>
                    <Form
                        form={form}
                        name="role"
                        layout="vertical"
                        initialValues={{isActive: true}}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        requiredMark={false}
                    >
                        <Row gutter={[8, 0]}>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Username" name="username"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 2, message: t('Minimum character length is', {length: '2'})},
                                        {max: 50, message: t('Maximum character length is', {length: '50'})}
                                    ]}
                                >
                                    <Input placeholder="Username"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Email" name="email"
                                    rules={[
                                        {type: 'email', message: t('Email is not a valid email!')},
                                        {min: 2, message: t('Minimum character length is', {length: '2'})},
                                        {max: 50, message: t('Maximum character length is', {length: '50'})}
                                    ]}
                                >
                                    <Input placeholder="Email"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Fullname" name="fullname"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 2, message: t('Minimum character length is', {length: '2'})},
                                        {max: 50, message: t('Maximum character length is', {length: '50'})}
                                    ]}
                                >
                                    <Input placeholder="fullname"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Password" name="password"
                                    rules={[
                                        {validator: ValidatePassword},
                                    ]}
                                >
                                    <Input.Password placeholder="Password"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item name="role" label="Role" rules={[{ required: true }]}>
                                    <Select
                                        placeholder="Role"
                                        // onChange={onGenderChange}
                                        allowClear
                                    >
                                        {
                                            roleList.map((x,idx)=> (
                                                <Option value={x.role} key={idx}>{x.role}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item name="isActive" label="Active" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Flex align="center" justify="space-between">
                                <Button type="primary" htmlType="submit" size="middle" loading={loading}> {t('Save')} </Button>
                            </Flex>
                        </Form.Item>
                    </Form>
                    </Card>
                </Col>
            </Row>
        </Flex>
    </div>
  );
};

export default UserEditor;
