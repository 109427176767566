import React, {useEffect, useRef} from 'react';
import {Button, CardProps, CarouselProps, Col, Flex, Row, Tooltip, Typography} from 'antd';
import { Helmet } from 'react-helmet-async';
import { useFetchData } from '../hooks';
import {ITEMS_HOME} from "../routes/ConfigRoutes";
import {useStylesContext} from "../context/StylesContext";
import {PageHeader} from "../components/PageHeader/PageHeader";
import {Card} from "../components/Card/Card";
import {AppCreator, AppName} from "../config/Config";
import {useTranslation} from "react-i18next";
import Summary, {ChildComponentRef} from "./utility/Closing/ClosingCurrentSummary";
import {FlexCustom} from "../components/Flex/FlexCustom";
import {ReloadOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

export const Home = () => {
    const { t } = useTranslation();
    const stylesContext = useStylesContext();

    const title = (
        <FlexCustom justifyContent={"space-between"}>
            <div>{t('Today Summary')}</div>
            <FlexCustom justifyContent="end">
                <Tooltip title={t("Recalculation")}>
                    <Button type="primary" shape="circle"
                        icon={ <ReloadOutlined/> }
                        onClick={() =>
                        {
                            if (childRef.current) {
                                // Execute the function in the child component from the parent
                                childRef.current.ReloadList();
                            }
                        }
                        }/>
                </Tooltip>
            </FlexCustom>
        </FlexCustom>
    )

    const childRef = useRef<ChildComponentRef>(null);
    useEffect(() => {
        if (childRef.current) {
            // Execute the function in the child component from the parent
            childRef.current.closeDateChanged(dayjs());
        }
    }, []);

    return (
        <div>
            <Helmet>
                <title>Home</title>
            </Helmet>
            <Flex vertical gap="middle">
                <PageHeader title="Home Dashboard" breadcrumbs={ITEMS_HOME}/>
                <Row {...stylesContext?.rowProps}>
                    <Col xs={24} lg={24}>
                        <Card title={title}>
                            <Summary ref={childRef}/>
                        </Card>
                    </Col>
                </Row>
            </Flex>
        </div>
    );
};
