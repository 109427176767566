import React, {useEffect,  useState} from "react";
import {useStylesContext} from "../../../context/StylesContext";
import {Button, Col, Flex, Form, Input, message, Row} from "antd";
import { useParams} from "react-router-dom";
import {StatusCode} from "../../../service/AppService";
import {SaveChangePassword} from "../../../service/ProfileServices";
import {IChangePasswordFormType} from "../../../types/LoginType";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {useLoginContext} from "../../../context/LoginProvider";
import {useTranslation} from "react-i18next";

type ChangePasswordProps = {
    username?:string
}
const ChangePassword:React.FC<ChangePasswordProps> = ({username =''}) => {
    const { t } = useTranslation();
    const stylesContext = useStylesContext();
    const {loggedIn} = useLoginContext();
    const effectiveUsername = username === '' ? loggedIn.username : username;
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm<IChangePasswordFormType>()
    const onFinish = async (values: IChangePasswordFormType) => {
        try {
            setLoading(true);
            const result = await SaveChangePassword(values);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                message.open({type: 'success', content: result.message});
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const ReloadData = async () => {
        console.log(effectiveUsername);
        form.setFieldValue('username', effectiveUsername);
    };

    useEffect(() => {
        ReloadData();
    }, []);



  return (
    <Row {...stylesContext?.rowProps}>
        <Col xs={24} lg={24}>
            <Form
                form={form}
                name="role"
                layout="vertical"
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                requiredMark={false}
            >
                <Row gutter={[8, 0]}>
                    <Col xs={24} lg={24}>
                        <Form.Item label={t("Username")} name="username">
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={24}>
                        <Form.Item label={t("Current Password")} name="old_password"
                            rules={[
                                {required: true, message: t('Cannot be empty')},
                                {min: 5, message: t('Minimum character length is', {length: '5'})},
                                {max: 50, message: t('Maximum character length is', {length: '50'})}
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder={t("Current Password")}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={24}>
                        <Form.Item label={t("New Password")} name="new_password"
                            rules={[
                                {required: true, message: t('Cannot be empty')},
                                {min: 5, message: t('Minimum character length is', {length: '5'})},
                                {max: 50, message: t('Maximum character length is', {length: '50'})}
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder={t("New Password")}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={24}>
                        <Form.Item label={t("Confirm Password")} name="confirm_password"
                            rules={[
                                {required: true, message: t('Cannot be empty')},
                                {min: 5, message: t('Minimum character length is', {length: '5'})},
                                {max: 50, message: t('Maximum character length is', {length: '50'})},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('new_password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(t('The new password you entered does not match')));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder={t("Confirm Password")}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Flex align="center" justify="space-between">
                        <Button type="primary" htmlType="submit" size="middle" loading={loading}> {t('Save')} </Button>
                    </Flex> </Form.Item>
            </Form>
        </Col>
    </Row>
  );
};

export default ChangePassword;
