import React, {CSSProperties, ReactNode, useEffect, useRef, useState} from "react";
import {
    ConfigProvider,
    Table,
    TableColumnsType,
} from "antd";

import {StatusCode} from "../../../service/AppService";
import {GetCurrentSummary, GetListClosing} from "../../../service/utility/ClosingServices";
import {useTranslation} from "react-i18next";
import './ClosingCurrentSummary.css';
import {IColumnSummaryOperatorBankAccountTransTypeByOperator} from "../../../types/utility/ClosingType";
import {IDataSummaryOperatorBankAccountTransTypeByOperator} from "../../../types/utility/ClosingType"; // Impor gaya kustom

type Props = {
    dataSourceIn:IDataSummaryOperatorBankAccountTransTypeByOperator[]
    dataColumnIn: IColumnSummaryOperatorBankAccountTransTypeByOperator[]
};
const ClosingCurrentSummaryBanAccountByOperator = ({ dataSourceIn, dataColumnIn }: Props)  => {
    const { t } = useTranslation();
    const [tableLoading, setTableLoading] = useState(false);
    // const [dataSource, setDataSource] = useState<ISummaryOperatorBankByOperator[]>([]);

    const [columns, setColumns] = useState<IColumnSummaryOperatorBankAccountTransTypeByOperator[]>([]);
    const [dataSource, setDataSource] = useState<IDataSummaryOperatorBankAccountTransTypeByOperator[]>([]);

    useEffect(() => {
        setDataSource(dataSourceIn);
        const mappedData = dataColumnIn.map((x,idx)=> (
            x.title === 'Operator' ?
                {title: t(x.title), dataIndex: x.dataIndex, key: x.key, ellipsis: true, fixed :'left'}
                : x.type === 'money' ? {title: t(x.title), dataIndex: x.dataIndex, key: x.key, ellipsis: true,
                    render: (text:any) => {
                        const formattedText = text % 1 === 0
                            ? new Intl.NumberFormat('en-US').format(text)
                            : new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(text);
                        return formattedText;
                    }
                } : {title: t(x.title), dataIndex: x.dataIndex, key: x.key, ellipsis: true, fixed :'left'}
        ));
        setColumns(mappedData);
    }, [dataSourceIn]);


    const rowClassName = (record: IDataSummaryOperatorBankAccountTransTypeByOperator, index: number): string => {
        return (index % 2 === 0 ? 'even-row ' : 'odd-row ');
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        cellFontSizeSM: 11,
                    },
                },
            }}
        >
        <Table rowKey="operator"
            pagination={false}
            bordered
            columns={columns}
            dataSource={dataSource}
            loading={tableLoading}
            rowClassName={rowClassName}
            scroll={{ x: true }}
            size="small" />
        </ConfigProvider>
  );
};

export default ClosingCurrentSummaryBanAccountByOperator;
