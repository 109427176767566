import React, {useEffect,  useState} from "react";
import {Helmet} from "react-helmet-async";
import {ITEMS_MASTER_MEMBER} from "../../../routes/ConfigRoutes";
import {useStylesContext} from "../../../context/StylesContext";
import {Button, Col, Flex, Form, Input, message, Row, Select, Switch, Tooltip} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {Card} from "../../../components/Card/Card";
import {PageHeader} from "../../../components/PageHeader/PageHeader";
import {useTranslation} from "react-i18next";
import {StatusCode} from "../../../service/AppService";
import {AppName} from "../../../config/Config";
import {ToNumber} from "../../../helper/Helper";
import {IBankListType} from "../../../types/master/BankAccountType";
import {IMemberFormType} from "../../../types/master/MemberType";
import {GetDetailMember, SaveMember} from "../../../service/master/MemberServices";
import {FlexCustom} from "../../../components/Flex/FlexCustom";
import {PlusOutlined} from "@ant-design/icons";
import CheckAcess from "../../../routes/Helper/CheckAcess";

const MemberEditor = () => {
    const { t } = useTranslation();
    const { memberIdParam } = useParams<{ memberIdParam: string }>();
    const navigate = useNavigate();
    const stylesContext = useStylesContext();
    const [memberId, setMemberId] = useState(ToNumber(memberIdParam));
    const [userId, setUserId] = useState('');
    const [loading, setLoading] = useState(false);
    const [bankList, setBankProviderList] = useState([] as IBankListType[])
    const [form] = Form.useForm<IMemberFormType>()
    const { Option } = Select;
    const title = (
        <FlexCustom justifyContent={"space-between"}>
            <div>{memberId === 0 ? t('NewFor', {field:'Member'}) : t(`ViewFor`, {field: 'Member',data: userId})}</div>
            <FlexCustom justifyContent="end">
                <Tooltip title={t("New Record")}>
                    <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={()=>navigate('/master/member/new')} />
                </Tooltip>
            </FlexCustom>
        </FlexCustom>
    )
    const onFinish = async (values: IMemberFormType) => {
        try {
            setLoading(true);
            if(memberId !== 0) if(!CheckAcess('Member.Edit')) return;
            else if(memberId === 0) if(!CheckAcess('Member.New')) return;
            const result = await SaveMember(memberId, values);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                setMemberId(ToNumber(result.data));
                setUserId(values.userId);
                message.open({type: 'success', content: result.message});
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const ReloadData = async () => {
        try {
            setLoading(true);
            console.log(memberId)
            const result = await GetDetailMember(memberId);

            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data;
                if(data.data.length !== 0) {
                    form.setFieldsValue({
                        memberId: data.data.memberId,
                        userId: data.data.userId,
                        email: data.data.email,
                        telp: data.data.telp,
                        bank: data.data.bank,
                        accountNumber: data.data.accountNumber,
                        accountName: data.data.accountName,
                        isActive: data.data.isActive,
                    });
                }
                console.log(data.bankList)
                setBankProviderList(data.bankList);
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        ReloadData();
    }, []);



  return (
    <div>
      <Helmet>
        <title>Member | {AppName}</title>
      </Helmet>
        <Flex vertical gap="middle">
            <PageHeader title="Member" breadcrumbs={ITEMS_MASTER_MEMBER}/>
            <Row {...stylesContext?.rowProps}>
                <Col xs={24} lg={24}>
                    <Card title={title}>
                    <Form
                        form={form}
                        name="bank"
                        layout="vertical"
                        initialValues={{isActive:true}}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        requiredMark={false}
                    >
                        <Row gutter={[8, 0]}>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="UserId" name="userId"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 2, message: t('Minimum character length is', {length: '2'})},
                                        {max: 50, message: t('Maximum character length is', {length: '50'})}
                                    ]}
                                >
                                    <Input placeholder="UserId"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Email" name="email"
                                    rules={[
                                        {type: 'email', message: t('Email is not a valid email!')},
                                        {min: 2, message: t('Minimum character length is', {length: '2'})},
                                        {max: 50, message: t('Maximum character length is', {length: '50'})}
                                    ]}
                                >
                                    <Input placeholder="Email"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Telephone Number" name="telp"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 2, message: t('Minimum character length is', {length: '2'})},
                                        {max: 50, message: t('Maximum character length is', {length: '50'})}
                                    ]}
                                >
                                    <Input placeholder="Telephone Number"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item name="bank" label="Bank" rules={[{ required: true }]}>
                                    <Select
                                        placeholder="Bank"
                                        // onChange={onGenderChange}
                                        allowClear
                                    >
                                        {
                                            bankList.map((x,idx)=> (
                                                <Option value={x.bank} key={idx}>{x.bank}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Bank Account Number" name="accountNumber"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 3, message: t('Minimum character length is', {length: '3'})},
                                        {max: 100, message: t('Maximum character length is', {length: '100'})}
                                    ]}
                                >
                                    <Input placeholder="Bank Account Number"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Bank Account Name" name="accountName"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 3, message: t('Minimum character length is', {length: '3'})},
                                        {max: 100, message: t('Maximum character length is', {length: '100'})}
                                    ]}
                                >
                                    <Input placeholder="Bank Account Name"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={4}>
                                <Form.Item name="isActive" label="Active" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Flex align="center" justify="space-between">
                                <Button type="primary" htmlType="submit" size="middle" loading={loading}> {t('Save')} </Button>
                            </Flex> </Form.Item>
                    </Form>
                    </Card>
                </Col>
            </Row>
        </Flex>
    </div>
  );
};

export default MemberEditor;
