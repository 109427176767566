import { Layout } from 'antd';
import {AppCreator, AppName} from "../../config/Config";

const { Footer } = Layout;

type FooterNavProps = React.HTMLAttributes<HTMLDivElement>;

const FooterNav = ({ ...others }: FooterNavProps) => {
  return (
    <Footer {...others}>{AppName} © 2023 Created by {AppCreator}</Footer>
  );
};

export default FooterNav;
