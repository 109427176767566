function path(root: string, sublink: string) {
    if (root.endsWith('/')) root = root.slice(0, -1);
    if (sublink.endsWith('/')) sublink = sublink.slice(0, -1);
    if (sublink.startsWith('/')) sublink = sublink.slice(1);
    return `${root}/${sublink}`;
}

const ROOT_HOME = '/';
const ROOT_TRANSACTION = '/transaction';
const ROOT_UTILITY = '/utility';
const ROOT_MASTER = '/master';
const ROOT_SETTING = '/setting';
const ROOT_ERRORS = '/errors';

export const PATH_HOME = {
    path: ROOT_HOME,
    home: ROOT_HOME,
    login: path(ROOT_HOME, '/login'),
    about: path(ROOT_HOME, '/about'),
};
export const PATH_SETTING = {
    path:ROOT_SETTING,
    user: path(ROOT_SETTING, '/user'),
    role: path(ROOT_SETTING, '/role'),
}
export const PATH_TRANSACTION = {
    path: ROOT_TRANSACTION,
    dpwd: path(ROOT_TRANSACTION, '/dpwd'),
    cashflow: path(ROOT_TRANSACTION, '/cashflow'),
    coinflow: path(ROOT_TRANSACTION, '/coinflow'),
}

export const PATH_UTILITY = {
    path: ROOT_UTILITY,
    closing: path(ROOT_UTILITY, '/closing'),
    today: path(ROOT_UTILITY, '/today_summary'),
}

export const PATH_MASTER = {
    path:ROOT_MASTER,
    bank: path(ROOT_MASTER, '/bank'),
    bank_account: path(ROOT_MASTER, '/bank_account_dpwd'),
    transType: path(ROOT_MASTER, '/trans_type'),
    member: path(ROOT_MASTER, '/member'),
}

export const PATH_ERROR = {
    path: ROOT_ERRORS,
    error400: path(ROOT_ERRORS, '/400'),
    error403: path(ROOT_ERRORS, '/403'),
    error404: path(ROOT_ERRORS, '/404'),
    error500: path(ROOT_ERRORS, '/500'),
    error503: path(ROOT_ERRORS, '/503'),
};
