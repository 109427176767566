import {Button, Select} from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const Language = () => {
    const isMobile = useMediaQuery({ maxWidth: 769 });
    const [language, setLanguage] = useState(
        ''// localStorage.getItem("language") ?? "english"
    );
    const { i18n } = useTranslation();

    // Fungsi untuk mengubah bahasa
    const changeLanguage = (lng: string) => {
        localStorage.setItem("language", lng);
        i18n.changeLanguage(lng);
    };

    // Mengubah bahasa berdasarkan pilihan dari Select
    const onLanguageChange = (value: string) => {
        setLanguage(value);
        changeLanguage(value);

    };

    // Efek samping untuk menyimpan bahasa di local storage dan mengubah bahasa saat nilai language berubah
    useEffect(() => {
        setLanguage(localStorage.getItem("language") ?? "english");
    }, [language]);
    // useEffect(() => {
    //     console.log('useEffect',localStorage.getItem("language") ?? "english", i18n);
    //
    //     setLanguage(localStorage.getItem("language") ?? "english");
    //     changeLanguage(localStorage.getItem("language") ?? "english");
    // }, []);

    return (
        <>
            <Select
                showSearch
                value={language} // Menggunakan value bukan defaultValue untuk mengikuti perubahan state
                options={[
                    { value: "english", label: "EN" },
                    { value: "indonesia", label: "ID" },
                    { value: "khmer", label: "KH" },
                    { value: "vietnam", label: "VI" },
                ]}
                onChange={onLanguageChange}
                style={{ width: isMobile ? "75px" : "75px" }}
            />
        </>
    );
};

export default Language;
