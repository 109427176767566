import React, {useEffect,  useState} from "react";
import {Helmet} from "react-helmet-async";
import {ITEMS_SETTING_ROLE} from "../../../routes/ConfigRoutes";
import {useStylesContext} from "../../../context/StylesContext";
import {Button, Checkbox, Col, Flex, Form, Input, message, Row, Tooltip} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import { GetDetailRole, SaveRole} from "../../../service/RoleServices";
import {Card} from "../../../components/Card/Card";
import {PageHeader} from "../../../components/PageHeader/PageHeader";
import {IAccessGroupType, IRoleAccessType, IRoleFormType} from "../../../types/RoleType";
import {useTranslation} from "react-i18next";
import {StatusCode} from "../../../service/AppService";
import {AppName} from "../../../config/Config";
import {FlexCustom} from "../../../components/Flex/FlexCustom";
import {PlusOutlined} from "@ant-design/icons";
import CheckAcess from "../../../routes/Helper/CheckAcess";

const RoleEditor = () => {
    const { t } = useTranslation();
    const { roleName } = useParams<{ roleName: string }>();
    const navigate = useNavigate();
    const stylesContext = useStylesContext();
    const [role, setRole] = useState(roleName??'');
    const [loading, setLoading] = useState(false);
    const [accessList, setAccessList] = useState([] as IRoleAccessType[])
    const [accessGroup, setAccessGroup] = useState([] as IAccessGroupType[])
    const [form] = Form.useForm<IRoleFormType>()
    const [accessValue, setAccessValue] = useState([] as string[] );
    // const title = () => {
    //     return role === '' ? t('NewFor', {field:'Role'}) : t(`ViewFor`, {field: 'Role',roleName: role});
    // }
    const title = (
        <FlexCustom justifyContent={"space-between"}>
            <div>{role === '' ? t('NewFor', {field:'Role'}) : t(`ViewFor`, {field: 'Role',data: role})}</div>
            <FlexCustom justifyContent="end">
                <Tooltip title={t("New Record")}>
                    <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={()=>navigate('/transaction/cashflow/new')} />
                </Tooltip>
            </FlexCustom>
        </FlexCustom>
    )
    const onFinish = async (values: IRoleFormType) => {
        try {
            setLoading(true);
            if(roleName != '') if(!CheckAcess('Role.Edit')) return;
            else if(roleName == '') if(!CheckAcess('Role.New')) return;
            const result = await SaveRole(role ?? '', values);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                setRole(values.role);
                message.open({type: 'success', content: result.message});
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const ReloadData = async () => {
        try {
            setLoading(true);
            const result = await GetDetailRole(role);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data;

                if(data.data.access !== undefined) {
                    setAccessValue(JSON.parse(data.data.access));
                    form.setFieldsValue({
                        role: data.data.role,
                        access: JSON.parse(data.data.access),
                    });
                }

                setAccessList(data.accessList);
                setAccessGroup(data.groupAccess);
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        ReloadData();
    }, []);



  return (
    <div>
      <Helmet>
        <title>Role | {AppName}</title>
      </Helmet>
        <Flex vertical gap="middle">
            <PageHeader title="Role" breadcrumbs={ITEMS_SETTING_ROLE}/>
            <Row {...stylesContext?.rowProps}>
                <Col xs={24} lg={24}>
                    <Card title={title}>
                    <Form
                        form={form}
                        name="role"
                        layout="vertical"
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        requiredMark={false}
                    >
                        <Row gutter={[8, 0]}>
                            <Col xs={24} lg={24}>
                                <Form.Item label="Role" name="role"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 2, message: t('Minimum character length is', {length: '2'})},
                                        {max: 50, message: t('Maximum character length is', {length: '50'})}
                                    ]}
                                >
                                    <Input placeholder="Role"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={24}>
                                <Form.Item label="Access" name="access" valuePropName="value">
                                    <Checkbox.Group
                                        value={accessValue}
                                        onChange={(value) => {
                                            setAccessValue(value as string[]);
                                        }}
                                        style={{width:'100%'}}
                                    >
                                        <Row>
                                            <Col xs={24}>
                                                {accessGroup.map((group,idx) => (
                                                    <Card title={group.group} key={idx} size="small" styles={{  body: { padding: '0' }}}>
                                                        <Row>
                                                            {group.subGroup.map((subGroup, idx2) => (
                                                                <Col xs={24} lg={12} key={idx2}>
                                                                    <Card title={subGroup} size="small" style={{  margin: '5px'}}>
                                                                        <Row>
                                                                            {accessList
                                                                                .filter(option => option.group === group.group && option.subGroup === subGroup)
                                                                                .map((option, idx3) => (
                                                                                    <Col xs={24} sm={12} md={6} lg={12} xl={8} xxl={6} key={idx3}>
                                                                                        <Checkbox value={option.accessKey}>{option.accessKey as string}</Checkbox>
                                                                                    </Col>
                                                                                ))}
                                                                        </Row>
                                                                    </Card>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </Card>
                                                ))}
                                            </Col>
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Flex align="center" justify="space-between">
                                <Button type="primary" htmlType="submit" size="middle" loading={loading}> {t('Save')} </Button>
                            </Flex> </Form.Item>
                    </Form>
                    </Card>
                </Col>
            </Row>
        </Flex>
    </div>
  );
};

export default RoleEditor;
