// export const baseURL:string = "https://markas.finance.localhost";
export const baseURL:string = "https://api.metaalva.online";
export interface IReturnResponse {
    data?: any | null,
    success: boolean,
    message: string,
    error?: any | null,
    status:number,
}

