import React, {useEffect, useRef, useState} from 'react';
import {ConfigProvider, Layout, Menu, MenuProps, SiderProps} from 'antd';
import {Link, useLocation} from 'react-router-dom';
import {COLOR} from '../../App';
import {NavItems} from "../../routes/ConfigNavBar";
import {ITEMS_HOME} from "../../routes/ConfigRoutes";
import {PATH_HOME} from "../../routes/RoutePathList";
import {Logo} from "../../components/Logo/Logo";

const {Sider} = Layout;

const items = NavItems;

const rootSubmenuKeys = ['dashboards'];

type SideNavProps = SiderProps;

const SideNav = ({...others}: SideNavProps) => {
    const nodeRef = useRef(null);
    const {pathname} = useLocation();
    const [openKeys, setOpenKeys] = useState(['']);
    const [current, setCurrent] = useState('');

    const onClick: MenuProps['onClick'] = (e) => {
        console.log('click ', e);
    };

    const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    useEffect(() => {
        const paths = pathname.split('/');
        setOpenKeys(paths);
        setCurrent(paths[paths.length - 1]);
    }, [pathname]);

    return (
        <Sider ref={nodeRef} breakpoint="lg" collapsedWidth="0" {...others}>
            <Logo
            color="black"
            asLink
            href={PATH_HOME.home}
            justify="center"
            gap="small"
            imgSize={{h: 28, w: 28}}
            style={{padding: '1rem 0'}}
            />
            <ConfigProvider
                theme={{
                    components: {
                        Menu: {
                            itemBg: 'none',
                            itemSelectedBg: COLOR['100'],
                            itemHoverBg: COLOR['50'],
                            itemSelectedColor: COLOR['600'],
                        },
                    },
                }
            }>
                <Menu
                mode="inline"
                items={items}
                onClick={onClick}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                selectedKeys={[current]}
                style={{border: 'none'}}
            />
            </ConfigProvider>
        </Sider>
    );
};

export default SideNav;
