import React, {createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {ILoginType} from "../types/LoginType";
import {eventEmitter} from "./EventEmitter";
import {GetAccess} from "../service/UserServices";
import {StatusCode} from "../service/AppService";

interface LoginProviderProps{
    children: React.ReactNode
}
interface ILoginContext{
    loggedIn: ILoginType;
    setLoggedIn: Dispatch<SetStateAction<ILoginType>>
}
const initialStateLogin:ILoginType =  {
    username: localStorage.getItem('username') ?? '',
    isLoggedIn : localStorage.getItem('isLogin') === "true",
    isTFAActive : localStorage.getItem('isTFAActive') === "true",
    isTFASuccess : localStorage.getItem('isTFASuccess') === "true",
}
export const LoginContext = createContext<ILoginContext>({loggedIn: initialStateLogin as ILoginType, setLoggedIn:() =>{}});

export const LoginProvider = ({children}: LoginProviderProps) => {
    const [loggedIn, setLoggedIn] = useState<ILoginType>( initialStateLogin as ILoginType);
    useEffect(() => {
        localStorage.setItem('username', loggedIn.username);
        localStorage.setItem('isLogin', loggedIn.isLoggedIn ? 'true' : 'false');
        localStorage.setItem('isTFAActive', loggedIn.isTFAActive ? 'true' : 'false');
        localStorage.setItem('isTFASuccess', loggedIn.isTFASuccess ? 'true' : 'false');
        // console.log('from contex awal islogin = ' + loggedIn.isLoggedIn + ', username = ' + localStorage.getItem('username'));
        if(loggedIn.isLoggedIn) ReloadAccess();
    }, [loggedIn]);
    useEffect(() => {
        const handleData = (data: string) => {
            if(data === 'Logout'){
                setLoggedIn({username:'', isLoggedIn:false, isTFAActive:false, isTFASuccess:false})
            }
        };

        eventEmitter.on('data', handleData);

        return () => {
            eventEmitter.off('data', handleData);
        };
    }, []);

    const ReloadAccess = async () =>{
        try {
            const result = await GetAccess();
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                localStorage.setItem('access', result.data.data);
                localStorage.setItem('root', result.data.root);
            }
            else return null;
        } catch (error:any){
            console.log('catch',error);
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            ReloadAccess();
        }, 25000); // 10000 ms = 10 detik

        // Membersihkan interval ketika komponen dihapus
        return () => clearInterval(intervalId);
    }, []);

    return (
        <LoginContext.Provider value={{loggedIn, setLoggedIn}}>
            {children}
        </LoginContext.Provider>
    );
};

export const useLoginContext = () => {
    return useContext(LoginContext)
}
