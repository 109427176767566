import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/english.json';
import translationID from './locales/indonesia.json';
import translationVI from './locales/vietnam.json';
import translationKH from './locales/khmer.json';

const resources = {
    indonesia: { translation: translationID },
    english: { translation: translationEN },
    vietnam: { translation: translationVI },
    khmer: { translation: translationKH },
};

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem("language") ?? "english", // bahasa default
        fallbackLng: "english",
         interpolation: {
            escapeValue: false,
        },
    })
    .then(() => console.log('i18next initialized'))
    .catch(err => console.error('i18next initialization error:', err));

export default i18next;
