import React, {CSSProperties, ReactNode, useEffect, useRef, useState} from "react";
import {
    ConfigProvider,
    Table,
    TableColumnsType,
} from "antd";

import {StatusCode} from "../../../service/AppService";
import {GetCurrentSummary, GetListClosing} from "../../../service/utility/ClosingServices";
import {useTranslation} from "react-i18next";
import './ClosingCurrentSummary.css';
import {ISummaryTransType} from "../../../types/utility/ClosingType"; // Impor gaya kustom

type Props = {
    dataSourceIn:ISummaryTransType[]
};
const ClosingCurrentSummaryTransType = ({ dataSourceIn }: Props)  => {
    const { t } = useTranslation();
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState<ISummaryTransType[]>([]);
    const columns: TableColumnsType<ISummaryTransType> = [
        {title: t('Transaction Type'), dataIndex: 'transType', key: 'transType', ellipsis: true, width: '75%'},
        {title: t('Amount'), dataIndex: 'amount', key: 'amount', width: '25%', align: 'center',
            render: (text) => {
                const formattedText = text % 1 === 0
                    ? new Intl.NumberFormat('en-US').format(text)
                    : new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(text);
                return formattedText;
            }
        }
    ];

    useEffect(() => {
        setDataSource(dataSourceIn);
    }, [dataSourceIn]);

    const rowClassName = (record: ISummaryTransType, index: number): string => {
        return (index % 2 === 0 ? 'even-row ' : 'odd-row ') + (record.transType === 'COIN AKHIR' ? 'sum-coin ' : '') + (record.transType === 'DANA AKHIR' ? 'sum-cash ' : '');
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        cellFontSizeSM: 11,
                    },
                },
            }}
        >
        <Table rowKey="transType"
            pagination={false}
            bordered
            columns={columns}
            dataSource={dataSource}
            loading={tableLoading}
            rowClassName={rowClassName}
            size="small" />
        </ConfigProvider>
  );
};

export default ClosingCurrentSummaryTransType;
