import React, {useEffect,  useState} from "react";
import {Helmet} from "react-helmet-async";
import {useStylesContext} from "../../../context/StylesContext";
import {
    Col, Descriptions,
    DescriptionsProps,
    Flex,
    Row,
    Tabs,
    TabsProps,
    Typography
} from "antd";
import {StatusCode} from "../../../service/AppService";
import {Card} from "../../../components/Card/Card";
import {GetUserInfo} from "../../../service/ProfileServices";
import ChangePassword from "./ChangePassword";
import {IUserProfileType} from "../../../types/LoginType";
import TFA from "./TFA";
import {useTranslation} from "react-i18next";

const { Link } = Typography;
const ProfileSetting = () => {
    const { t } = useTranslation();
    const stylesContext = useStylesContext();
    const [loading, setLoading] = useState(false);
    const [userprofile, setUserProfile] = useState({} as IUserProfileType);
    const ReloadData = async () => {
        try {
            setLoading(true);
            const result = await GetUserInfo();
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data;
                setUserProfile(data);
                console.log(data);
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        ReloadData();
    }, []);

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: t('Change Password'),
            children: (<ChangePassword username={userprofile.username}/>),
        },
        {
            key: '2',
            label: t('2FA Authentication'),
            children: <TFA username={userprofile.username} isTFAActive={userprofile.isTFAActive}/>,
        },
    ];
    const onChange = (key: string) => {
        console.log(key);
    };
    const DESCRIPTION_ITEMS: DescriptionsProps['items'] = [
        {
            key: 'username',
            label: t('Username'),
            children: <span>{userprofile.username}</span>,
        },
        {
            key: 'full-name',
            label: t('Name'),
            children: <span>{userprofile.fullname}</span>,
        },
        {
            key: 'role',
            label: t('Role Access'),
            children: <span>{userprofile.role}</span>,
        },
        {
            key: 'TFA',
            label: t('2FA'),
            children: <span>{userprofile.isTFAActive ? t('Enable') : t('Disable')}</span>,
        },
    ];
  return (
    <div>
      <Helmet>
        <title>User Info</title>
      </Helmet>
        <Flex vertical gap="middle">
            <Row {...stylesContext?.rowProps}>
                <Col xs={24} lg={24}>
                    <Card title={t('User Info')} loading={loading}>
                        <Row>
                            <Col xs={24} sm={16} lg={20}>
                                <Descriptions
                                    items={DESCRIPTION_ITEMS}
                                    column={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 4 }}
                                />
                            </Col>
                        </Row>
                        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                    </Card>
                </Col>
            </Row>
        </Flex>
    </div>
  );
};

export default ProfileSetting;
