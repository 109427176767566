import React, {useEffect,  useState} from "react";
import {Helmet} from "react-helmet-async";
import {ITEMS_MASTER_BANK_ACCOUNT} from "../../../routes/ConfigRoutes";
import {useStylesContext} from "../../../context/StylesContext";
import {Button, Col, DatePicker, Flex, Form, Input, InputNumber, message, Row, Select, Switch} from "antd";
import {useParams} from "react-router-dom";
import {Card} from "../../../components/Card/Card";
import {PageHeader} from "../../../components/PageHeader/PageHeader";
import {useTranslation} from "react-i18next";
import {StatusCode} from "../../../service/AppService";
import {AppName} from "../../../config/Config";
import {BankAccountProps, IBankAccountFormType, IBankListType} from "../../../types/master/BankAccountType";
import {GetDetailBankAccount, SaveBankAccount} from "../../../service/master/BankAccountServices";
import {ToNumber} from "../../../helper/Helper";
import dayjs from "dayjs";
import {isEmpty} from "lodash";
import CheckAcess from "../../../routes/Helper/CheckAcess";

const BankAccountEditor: React.FC<BankAccountProps> = ({moduleType}) => {
    const { t } = useTranslation();
    const { bankAccountIdParam } = useParams<{ bankAccountIdParam: string }>();
    const stylesContext = useStylesContext();
    const [bankAccountId, setBankAccountId] = useState(ToNumber(bankAccountIdParam));
    const [bankAccountCode, setBankAccountCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [bankList, setBankList] = useState([] as IBankListType[])
    const [form] = Form.useForm<IBankAccountFormType>()
    const { Option } = Select;
    const title = () => {
        return bankAccountId === 0 ? t('NewFor', {field:'Bank'}) : t(`ViewFor`, {field: 'Bank',data: bankAccountCode});
    }
    const onFinish = async (values: IBankAccountFormType) => {
        try {
            setLoading(true);
            if(bankAccountId !== 0) if(!CheckAcess('BankAccount.Edit')) return;
            else if(bankAccountId === 0) if(!CheckAcess('BankAccount.New')) return;
            const dateValue = dayjs(values.phoneExpiredDate);
            const formattedValues = {
                ...values,
                phoneExpiredDate: dateValue.format('YYYY-MM-DD'),
            };
            const result = await SaveBankAccount(moduleType, bankAccountId, formattedValues);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                setBankAccountId(ToNumber(result.data));
                setBankAccountCode(values.bankAccountCode);
                message.open({type: 'success', content: result.message});
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const ReloadData = async () => {
        try {
            setLoading(true);
            const result = await GetDetailBankAccount(moduleType, bankAccountId);

            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data;
                if(data.data.length !== 0) {
                    form.setFieldsValue({
                        bankAccountId: data.data.bankAccountId,
                        bankAccountCode: data.data.bankAccountCode,
                        moduleType: data.data.moduleType,
                        bank: data.data.bank,
                        bankAccountNumber: data.data.bankAccountNumber,
                        bankAccountName: data.data.bankAccountName,
                        phoneNumber: data.data.phoneNumber,
                        phoneExpiredDate: isEmpty(data.data.phoneExpiredDate) ? '' :dayjs(data.data.phoneExpiredDate),
                        phoneRemainPulsa: data.data.phoneRemainPulsa,
                        userInternetBanking: data.data.userInternetBanking,
                        pwdInternetBanking: data.data.pwdInternetBanking,
                        userMobileBanking: data.data.userMobileBanking,
                        pwdMobileBanking: data.data.pwdMobileBanking,
                        pinTransactionBanking: data.data.pinTransactionBanking,
                        description: data.data.description,
                        isLimit: data.data.isLimit,
                        isPremium: data.data.isPremium,
                        isBlocked: data.data.isBlocked,
                        isActive: data.data.isActive,
                        seq: data.data.seq
                    });
                }
                setBankList(data.bankList);
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        ReloadData();
    }, []);



  return (
    <div>
      <Helmet>
        <title>Bank Account DPWD |  {AppName}</title>
      </Helmet>
        <Flex vertical gap="middle">
            <PageHeader title="Bank DPWD" breadcrumbs={ITEMS_MASTER_BANK_ACCOUNT}/>
            <Row {...stylesContext?.rowProps}>
                <Col xs={24} lg={24}>
                    <Card title={title()}>
                    <Form
                        form={form}
                        name="bank"
                        layout="vertical"
                        initialValues={{moduleType: 'DPWD', phoneExpiredDate: dayjs(), phoneRemainPulsa: 0, seq:0, isLimit:false, isPremium: false, isBlocked: false, isActive:true, }}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        requiredMark={false}

                    >
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label="Bank Account" name="bankAccountCode"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 2, message: t('Minimum character length is', {length: '2'})},
                                        {max: 50, message: t('Maximum character length is', {length: '50'})}
                                    ]}
                                >
                                    <Input placeholder="Enter Bank Account Code"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item name="bank" label="Bank" rules={[{ required: true }]}>
                                    <Select placeholder="Select a Bank" allowClear>
                                        {
                                            bankList.map((x,idx)=> (
                                                <Option value={x.bank} key={idx}>{x.bank}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label="Bank Account Number" name="bankAccountNumber"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 3, message: t('Minimum character length is', {length: '3'})},
                                        {max: 100, message: t('Maximum character length is', {length: '100'})}
                                    ]}
                                >
                                    <Input placeholder="Enter Bank Account Number"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label="Bank Account Name" name="bankAccountName"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 3, message: t('Minimum character length is', {length: '3'})},
                                        {max: 100, message: t('Maximum character length is', {length: '100'})}
                                    ]}
                                >
                                    <Input placeholder="Enter Bank Account Name"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label="Phone Number" name="phoneNumber"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 3, message: t('Minimum character length is', {length: '9'})},
                                        {max: 100, message: t('Maximum character length is', {length: '100'})}
                                    ]}
                                >
                                    <Input placeholder="Enter Phone Number"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label="Expired Date" name="phoneExpiredDate" rules={[{required: true, message: t('Cannot be empty')}]}>
                                    <DatePicker format="YYYY-MM-DD" style={{width:'100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label="Remain Pulsa" name="phoneRemainPulsa"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                    ]}
                                >
                                    <InputNumber min={0} max={999} style={{ width: '100%' }} placeholder="Enter Remain Pulsa"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label="User Internet Banking" name="userInternetBanking">
                                    <Input placeholder="Enter User Internet Banking"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label="Password Internet Banking" name="pwdInternetBanking">
                                    <Input placeholder="Enter Password Internet Banking"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label="User Mobile Banking" name="userMobileBanking">
                                    <Input placeholder="Enter User Mobile Banking"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label="Password Mobile Banking" name="pwdMobileBanking">
                                    <Input placeholder="Enter Password Mobile Banking"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label="Pin Transaction Banking" name="pinTransactionBanking">
                                    <Input placeholder="Enter Pin Transaction Banking"/>
                                </Form.Item>
                            </Col>


                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item label="Description" name="description"
                                    rules={[
                                        {max: 255, message: t('Maximum character length is', {length: '255'})}
                                    ]}
                                >
                                    <Input placeholder="Enter Description"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={4}>
                                <Form.Item label="Sequence" name="seq"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                    ]}
                                >
                                    <InputNumber min={0} max={999} style={{ width: '100%' }} placeholder="Enter Sequence"/>
                                </Form.Item>
                            </Col>
                            {/*<Col xs={24} md={12} lg={4}>*/}
                            {/*    <Form.Item label="Module Type" name="moduleType"*/}
                            {/*        rules={[*/}
                            {/*            {required: true, message: t('Cannot be empty')},*/}
                            {/*            {min: 2, message: t('Minimum character length is', {length: '2'})},*/}
                            {/*            {max: 50, message: t('Maximum character length is', {length: '50'})}*/}
                            {/*        ]}*/}
                            {/*    >*/}
                            {/*        <Input placeholder="Module Type" disabled={true}/>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                        </Row>
                        <Row gutter={[8, 0]}>
                            <Col xs={8} sm={6} md={4} lg={2}>
                                <Form.Item label="Limit" name="isLimit" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col xs={8} sm={6} md={4} lg={2}>
                                <Form.Item label="Premium" name="isPremium" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col xs={8} sm={6} md={4} lg={2}>
                                <Form.Item label="Blocked" name="isBlocked" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col xs={8} sm={6} md={4} lg={2}>
                                <Form.Item label="Active" name="isActive" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Flex align="center" justify="space-between">
                                <Button type="primary" htmlType="submit" size="middle" loading={loading}> {t('Save')} </Button>
                            </Flex> </Form.Item>
                    </Form>
                    </Card>
                </Col>
            </Row>
        </Flex>
    </div>
  );
};

export default BankAccountEditor;
