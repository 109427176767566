import React from 'react';
import {GetCheckAccess} from "../../service/UserServices";
import {message} from "antd";

const CheckAcess = (accessCode:string = '') => {
    if(accessCode != '') {
        const AccessOK = GetCheckAccess(accessCode);

        if (AccessOK) {
            return true;
        } else {
            message.open({type: 'error', content: `Access Denied, Need access code (${accessCode})`});
            return false
        }
    }
    else return true;
};

export default CheckAcess;
