import {createBrowserRouter, Navigate, useLocation} from 'react-router-dom';
import React, { ReactNode, useEffect } from 'react';
import Login from "../pages/authentication/Login";
import {ErrorPage, Error400Page, Error403Page, Error404Page, Error500Page, Error503Page} from "../pages/errors";
import {Home} from "../pages/Home";
import {About} from "../pages/About";
import {AppLayout} from "../layouts/app/App";
import {useLoginContext} from "../context/LoginProvider";
import {WelcomePage} from "../pages/authentication/Welcome";
import Role from "../pages/setting/Role/Role";
import RoleEditor from "../pages/setting/Role/RoleEditor";
import ProfileSetting from "../pages/setting/Profile/ProfileSetting";
import User from "../pages/setting/User/User";
import UserEditor from "../pages/setting/User/UserEditor";
import Bank from "../pages/master/Bank/Bank";
import BankEditor from "../pages/master/Bank/BankEditor";
import BankAccount from "../pages/master/BankAccount/BankAccount";
import BankAccountEditor from "../pages/master/BankAccount/BankAccountEditor";
import TransType from "../pages/master/TransType/TransType";
import TransTypeEditor from "../pages/master/TransType/TransTypeEditor";
import Member from "../pages/master/Member/Member";
import MemberEditor from "../pages/master/Member/MemberEditor";
import DPWD from "../pages/transaction/DPWD/DPWD";
import DPWDEditor from "../pages/transaction/DPWD/DPWDEditor";
import CashFlow from "../pages/transaction/CashFlow/CashFlow";
import CashFlowEditor from "../pages/transaction/CashFlow/CashFlowEditor";
import CoinFlow from "../pages/transaction/CoinFlow/CoinFlow";
import CoinFlowEditor from '../pages/transaction/CoinFlow/CoinFlowEditor';
import Closing from "../pages/utility/Closing/Closing";
import ClosingEditor from "../pages/utility/Closing/ClosingEditor";
import ViewTodaySummary from "../pages/utility/Closing/ViewTodaySummary";
import ProtectedRoute, {ProtectedProps} from "./Helper/ProtectedRoute";

// Custom scroll restoration function
export const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    }); // Scroll to the top when the location changes
  }, [pathname]);

  return null; // This component doesn't render anything
};

export type PageProps = {
  children: ReactNode;
};

// Create an HOC to wrap your route components with ScrollToTop
const PageWrapper = ({ children }: PageProps) => {
  return (
      <>
        <ScrollToTop />
        {children}
      </>
  );
};
const LoginRoute = () => {
  const { loggedIn} = useLoginContext();

  return ((loggedIn.isLoggedIn && loggedIn.isTFAActive && loggedIn.isTFASuccess) || (loggedIn.isLoggedIn && !loggedIn.isTFAActive) ? <WelcomePage/> : <Login />);
};

const DefautWrapper = ({ children, accessCode } : ProtectedProps)=> {
  return (
      <>
        <ProtectedRoute accessCode={accessCode}>
          <AppLayout>
            <ScrollToTop />
            {children}
          </AppLayout>
        </ProtectedRoute>
      </>
  );
};
const EmptyWrapper = ({ children }: PageProps) => {
  return (
      <>
        <ScrollToTop />
        {children}
      </>
  );
};
// Create the router
const router = createBrowserRouter([
  {
    path: '/',
    element: <DefautWrapper children={<Home />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/about',
    element: <DefautWrapper children={<About />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: <EmptyWrapper children={<LoginRoute />} />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/setting',
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'role',
        element: <DefautWrapper children={<Role />} accessCode={'Role.View'} />,
      },
      {
        path: 'role/new',
        element: <DefautWrapper children={<RoleEditor/>} />,
      },
      {
        path: 'role/:roleName',
        element: <DefautWrapper children={<RoleEditor/>} />,
      },
      {
        path: 'user',
        element: <DefautWrapper children={<User />} />,
      },
      {
        path: 'user/new',
        element: <DefautWrapper children={<UserEditor/>} />,
      },
      {
        path: 'user/:username',
        element: <DefautWrapper children={<UserEditor/>} />,
      },
    ],
  },
  {
    path: '/transaction',
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'dpwd',
        element: <DefautWrapper children={<DPWD />} />,
      },
      {
        path: 'dpwd/new',
        element: <DefautWrapper children={<DPWDEditor/>} />,
      },
      {
        path: 'dpwd/:dpwdIdParam',
        element: <DefautWrapper children={<DPWDEditor/>} />,
      },
      {
        path: 'cashflow',
        element: <DefautWrapper children={<CashFlow />} />,
      },
      {
        path: 'cashflow/new',
        element: <DefautWrapper children={<CashFlowEditor/>} />,
      },
      {
        path: 'cashflow/:cashflowIdParam',
        element: <DefautWrapper children={<CashFlowEditor/>} />,
      },
      {
        path: 'coinflow',
        element: <DefautWrapper children={<CoinFlow />} />,
      },
      {
        path: 'coinflow/new',
        element: <DefautWrapper children={<CoinFlowEditor/>} />,
      },
      {
        path: 'coinflow/:coinflowIdParam',
        element: <DefautWrapper children={<CoinFlowEditor/>} />,
      }
    ]
  },
  {
    path: '/utility',
    errorElement: <ErrorPage/>,
    children: [
      {
        path: 'closing',
        element: <DefautWrapper children={<Closing/>}/>,
      },
      {
        path: 'closing/new',
        element: <DefautWrapper children={<ClosingEditor/>} />,
      },
      {
        path: 'closing/:closingIdParam',
        element: <DefautWrapper children={<ClosingEditor/>} />,
      },
      {
        path: 'today_summary',
        element: <DefautWrapper children={<ViewTodaySummary/>} />,
      },
    ]
  },
  {
    path: '/master',
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'bank',
        element: <DefautWrapper children={<Bank />} />,
      },
      {
        path: 'bank/new',
        element: <DefautWrapper children={<BankEditor/>} />,
      },
      {
        path: 'bank/:bankParam',
        element: <DefautWrapper children={<BankEditor/>} />,
      },
      {
        path: 'bank_account_dpwd',
        element: <DefautWrapper children={<BankAccount moduleType={'DPWD'} />} />,
      },
      {
        path: 'bank_account_dpwd/new',
        element: <DefautWrapper children={<BankAccountEditor moduleType={'DPWD'}/>} />,
      },
      {
        path: 'bank_account_dpwd/:bankAccountIdParam',
        element: <DefautWrapper children={<BankAccountEditor moduleType={'DPWD'}/>} />,
      },
      {
        path: 'trans_type',
        element: <DefautWrapper children={<TransType />} />,
      },
      {
        path: 'trans_type/new',
        element: <DefautWrapper children={<TransTypeEditor/>} />,
      },
      {
        path: 'trans_type/:transTypeIdParam',
        element: <DefautWrapper children={<TransTypeEditor/>} />,
      },
      {
        path: 'member',
        element: <DefautWrapper children={<Member />} />,
      },
      {
        path: 'member/new',
        element: <DefautWrapper children={<MemberEditor/>} />,
      },
      {
        path: 'member/:memberIdParam',
        element: <DefautWrapper children={<MemberEditor/>} />,
      },
    ],
  },
  {
    path: '/profile',
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'setting',
        element: <DefautWrapper children={<ProfileSetting />} />,
      }
    ]
  },
  {
    path: '/errors',
    errorElement: <ErrorPage />,
    children: [
      {
        path: '400',
        element: <Error400Page />,
      },
      {
        path: '403',
        element: <Error403Page />,
      },
      {
        path: '404',
        element: <Error404Page />,
      },
      {
        path: '500',
        element: <Error500Page />,
      },
      {
        path: '503',
        element: <Error503Page />,
      },
    ],
  },
]);

export default router;
