import React, {useCallback, useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    ITEMS_MASTER_MEMBER,
    ITEMS_TRANSACTION_CAHSFLOW,
    ITEMS_UTILITY_TODAY_SUMMARY,
} from "../../../routes/ConfigRoutes";
import {useStylesContext} from "../../../context/StylesContext";
import {
    Button,
    Col,
    Flex,
    Row,
    Tooltip
} from "antd";
import {Card} from "../../../components/Card/Card";
import {PageHeader} from "../../../components/PageHeader/PageHeader";
import {useTranslation} from "react-i18next";
import {AppName} from "../../../config/Config";
import {ReloadOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

import {FlexCustom} from "../../../components/Flex/FlexCustom";
import Summary, {ChildComponentRef} from "./ClosingCurrentSummary";

const ViewTodaySummary = () => {
    const {t} = useTranslation();
    const stylesContext = useStylesContext();

    const title = (
        <FlexCustom justifyContent={"space-between"}>
            <div>{t('Today Summary')}</div>
            <FlexCustom justifyContent="end">
                <Tooltip title={t("Recalculation")}>
                    <Button type="primary" shape="circle"
                        icon={ <ReloadOutlined/> }
                        onClick={() =>
                        {
                            if (childRef.current) {
                                // Execute the function in the child component from the parent
                                childRef.current.ReloadList();
                            }
                        }
                    }/>
                </Tooltip>
            </FlexCustom>
        </FlexCustom>
    )

    const childRef = useRef<ChildComponentRef>(null);

    useEffect(() => {
        if (childRef.current) {
            // Execute the function in the child component from the parent
            childRef.current.closeDateChanged(dayjs());
        }
    }, []);

    return (
        <div>
            <Helmet>
                <title>Closing | {AppName}</title>
            </Helmet>
            <Flex vertical gap="middle">
                <PageHeader
                    title="Today Summary"
                    breadcrumbs={ITEMS_UTILITY_TODAY_SUMMARY}
                />
                <Row {...stylesContext?.rowProps}>
                    <Col xs={24} lg={24}>
                        <Card title={title}>
                            <Row gutter={[8, 0]}>
                                <Col xs={24}>
                                    <Summary ref={childRef}/>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Flex>
        </div>
    );
};
export default ViewTodaySummary;
