import {BrowserRouter, RouterProvider} from 'react-router-dom';
import {ConfigProvider, theme} from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import { StylesContext } from './context/StylesContext';
import routes from './routes/Routes';

import './App.css';
import {LoginProvider} from "./context/LoginProvider";

// color palettes: triadic #A1A7CB, #CBA1A7, #A7CBA1
// 10 color objects of primary #2378c3 as generated by https://smart-swatch.netlify.app/#2378c3
// This is for reference purposes

export const COLOR = {
    50: '#e0f1ff',
    100: '#b0d2ff',
    200: '#7fb0ff',
    300: '#4d8bff',
    400: '#1e79fe',
    500: '#076ee5',
    600: '#0062b3',
    700: '#004f81',
    800: '#003650',
    900: '#001620',
    borderColor: '#E7EAF3B2',
};

function App() {
    return (
        <HelmetProvider>
            <ConfigProvider
                theme={{

                    token: {
                        colorPrimary: COLOR['500'],
                        borderRadius: 6,
                        fontFamily: 'Lato, sans-serif',
                    },
                    components: {
                        Breadcrumb: {
                            linkColor: 'rgba(0,0,0,.8)',
                            itemColor: 'rgba(0,0,0,.8)',
                        },
                        Button: {
                            colorLink: COLOR['500'],
                            colorLinkActive: COLOR['700'],
                            colorLinkHover: COLOR['300'],
                        },
                        Calendar: {
                            colorBgContainer: 'none',
                        },
                        Card: {
                            colorBgContainer: 'none',
                            colorBorderSecondary: COLOR['borderColor'],
                        },
                        Carousel: {
                            colorBgContainer: COLOR['800'],
                            dotWidth: 8,
                        },
                        Form: {
                            itemMarginBottom: 15,
                            verticalLabelPadding: '0 0 4px'
                        },
                        Rate: {
                            colorFillContent: COLOR['100'],
                            colorText: COLOR['600'],
                        },
                        Segmented: {
                            colorBgLayout: COLOR['100'],
                            borderRadius: 6,
                            colorTextLabel: '#000000',
                        },
                        Table: {
                            borderColor: COLOR['100'],
                            rowHoverBg: COLOR['50'],

                            cellPaddingInlineSM: 5,
                            cellPaddingBlockSM: 0,
                            headerBorderRadius: 0,
                            stickyScrollBarBg: COLOR['200'],
                            stickyScrollBarBorderRadius: 0,
                            headerBg: COLOR['200'],
                        },
                        Tabs: {
                            colorBorderSecondary: COLOR['100'],
                        },
                        Timeline: {
                            dotBg: 'none',
                        },
                        Typography: {
                            colorLink: COLOR['500'],
                            colorLinkActive: COLOR['700'],
                            colorLinkHover: COLOR['300'],
                            linkHoverDecoration: 'underline',
                        },
                        Select: {
                            optionHeight: 18,
                            optionFontSize: 13,
                            optionPadding: '2px 12px',
                            optionLineHeight: 1
                        }
                    },
                }}
            >
                <StylesContext.Provider
                    value={{
                        rowProps: {
                            gutter: [
                                { xs: 8, sm: 16, md: 24, lg: 32 },
                                { xs: 8, sm: 16, md: 24, lg: 32 },
                            ],
                        },
                        carouselProps: {
                            autoplay: true,
                            dots: true,
                            dotPosition: 'bottom',
                            infinite: true,
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        },
                    }}
                >
                    <LoginProvider>
                        <RouterProvider router={routes} />
                    </LoginProvider>
                </StylesContext.Provider>
            </ConfigProvider>
        </HelmetProvider>
    );
}

export default App;
