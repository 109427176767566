import React from 'react';
import { useNavigate } from 'react-router-dom';
import {GetCheckAccess} from "../../service/UserServices";
import {message} from "antd";
import {useTranslation} from "react-i18next";

type AccessLinkProps = {
    to: string;
    accessCode?: string;
    children: React.ReactNode;
};

const AccessLink: React.FC<AccessLinkProps> = ({ to, accessCode = '', children }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault(); // Mencegah navigasi default
        if(accessCode !== '') {
            const AccessOK = GetCheckAccess(accessCode);

            if (AccessOK) {
                navigate(to); // Hanya navigasi jika pengguna memiliki akses
            } else {
                message.open({type: 'error', content: t('Access Denied, Need access code', {accessCode: accessCode})});
                // Atau Anda bisa membiarkan kosong atau melakukan sesuatu yang lain di sini.
            }
        }
        else navigate(to);
    };

    return (
        <a href={to} onClick={handleClick}>
            {children}
        </a>
    );
};

export default AccessLink;
