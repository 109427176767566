import { Result } from 'antd';
import {BackBtn} from "../../components/BackBtn/BackBtn";
import {useTranslation} from "react-i18next";
export const Error404Page = () => {
  const { t } = useTranslation();
  return (
    <Result
      status="404"
      title="404"
      subTitle={t("Sorry, the page you visited does not exist.")}
      extra={<BackBtn type="primary" />}
    />
  );
};
