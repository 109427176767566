import {baseURL, IReturnResponse} from "../types/AppServiceType";
import {message} from "antd";
import {eventEmitter} from "../context/EventEmitter";
import axios from "axios";

export enum StatusCode {
    Code200_OK = 200, //200 OK: Permintaan berhasil dan server mengembalikan hasil yang diminta.
    Code400_InvalidInput = 400, //400 Bad Request: Permintaan tidak dapat diproses karena kesalahan klien.
    Code401_NotLogged = 401, //401 Unauthorized: Permintaan memerlukan otentikasi pengguna.
    Code403_NoAccess = 403, //403 Forbidden: Server memahami permintaan tetapi menolak untuk mengizinkannya.
    Code404_PageNotFound = 404, //404 Not Found: Sumber daya yang diminta tidak ditemukan.
    Code500_Error = 500, //500 Internal Server Error: Server menemukan situasi yang tidak dapat dipahami atau tidak dapat diproses.
}
export const client = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    timeout: 30000
});
client.defaults.withCredentials = true;
axios.defaults.withCredentials = true;

export const  ResponseChecker = (response : IReturnResponse) => {
    // const { loggedIn,setLoggedIn } = useLoginContext();
    if(response.status === StatusCode.Code401_NotLogged){
        message.open({type: 'error', content: response.message});
        // setLoggedIn({username:'', isLoggedIn: false});
        eventEmitter.emit('data', 'Logout');
    }
    else if(response.status === StatusCode.Code403_NoAccess){
        message.open({type: 'error', content: response.message});
    }
    else if(response.status === StatusCode.Code404_PageNotFound){
        message.open({type: 'error', content: response.message});
    }
    else if(response.status === StatusCode.Code400_InvalidInput){
        message.open({type: 'error', content: response.message});
    }
    else if(response.status === StatusCode.Code500_Error){
        message.open({type: 'error', content: response.message});
    }
}
export const ReturnCatchResponse = (errMsg:string):IReturnResponse => {
    return { success: false, status: StatusCode.Code500_Error, message: errMsg } as IReturnResponse;
}

//
// export async function MakeHTTPRequest(url: string, options:any):Promise<IReturnDataType> {
//     return new Promise<IReturnDataType>((resolve, reject) => {
//         fetch(url, options)
//             .then(response => {
//                 console.log('app service then 1', response);
//                 return response.json().then(data => ({
//                     data:data,
//                     status: response.status,
//                 } as IReturnDataType));
//             })
//             .then(data => {
//                 console.log('app service then 2', data);
//                 if(data.status === StatusCode.Code200_OK) {
//                     console.log('app service 2 resolve', data);
//                     resolve(data);
//                 }
//                 else{
//                     console.log('app service 2 reject', data);
//                     reject(data);
//                 }
//             })
//             .catch(error => {
//                 console.log('app service reject2', error);
//                 console.log(error);
//                 reject(error);
//             });
//     });
// }

export const AllErrorType =[
    StatusCode.Code400_InvalidInput,
    StatusCode.Code401_NotLogged,
    StatusCode.Code403_NoAccess,
    StatusCode.Code404_PageNotFound,
    StatusCode.Code500_Error
]
