import { IReturnResponse} from "../types/AppServiceType";
import {AxiosRequestConfig} from "axios";
import {AllErrorType, client, ResponseChecker, ReturnCatchResponse} from "./AppService";
import {IUserFormType} from "../types/UserType";


export const GetCheckAccess = (accessCode : string) : boolean => {
    const permissions = localStorage.getItem('access');
    const root = localStorage.getItem('root');
    const username = localStorage.getItem('username') ?? ''
    if(root == username) {
        return true;
    }
    if (permissions) {
        const permissionArray = permissions.split(',');
        return permissionArray.includes(accessCode);
    }
    else return false;
}
export const GetAccess = async () : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    return await client.get<IReturnResponse>(`/api/setting/user/getaccess`,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const GetListUser = async () : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    return await client.get<IReturnResponse>("/api/setting/user",config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const GetDetailUser = async (user:string = '') : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const url = user === '' ? `/api/setting/user/new/` : `/api/setting/user/${user}`;
    return await client.get<IReturnResponse>(url,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const DeleteUser = async (username:string) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',

        },
    };
    return await client.delete<IReturnResponse>(`/api/setting/user/${username}`,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const SaveUser = async (oldUsername:string, formData:IUserFormType) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const url = oldUsername === '' ? `/api/setting/user/new` : `/api/setting/user/${oldUsername}`;
    return await client.post<IReturnResponse>( url,formData, config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
