import React, { useState} from "react";
import {useStylesContext} from "../../../context/StylesContext";
import {Button, Checkbox, Col, Flex, Form, Input, message, Modal, QRCode, Row} from "antd";
import {StatusCode} from "../../../service/AppService";
import {GenerateTFAKey, TFAActivateNow, TFADeactivateNow} from "../../../service/ProfileServices";
import {
    IGeneratedTFAUrlKeyType,
    ITFAActivationFornmType, ITFADeactivationFornmType
} from "../../../types/LoginType";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

type TFAProps = {
    username?:string,
    isTFAActive?:boolean
}

const TFA:React.FC<TFAProps> = ({username='',isTFAActive = false}) => {
    const { t } = useTranslation();
    const stylesContext = useStylesContext();
    const [isActualTFAActive, setIsActualTFAActive] = useState(isTFAActive);
    const [loading, setLoading] = useState(false);
    const [modal, contextModalHolder] = Modal.useModal();
    const [tfaUrl, setTFAUrl] = useState<IGeneratedTFAUrlKeyType>({key:'', otpAuthUrl:''} );
    const [form] = Form.useForm<ITFAActivationFornmType>();
    const [isDeactivating, setIsDeactivating] = useState(false);
    const handleActivationButton = () => {
        modal.confirm({
            title: t('Please Read'),
            icon: <ExclamationCircleOutlined />,
            content: t('After you confirm, a barcode will appear. Please scan it using Google Authenticator.'),
            okText: t('OK'),
            cancelText: t('Cancel'),
            onOk: getBarcode
        });
    };
    const handleDeactivationButton = () => {
        modal.confirm({
            title: t('Please Read'),
            icon: <ExclamationCircleOutlined />,
            content: t('After you confirm, 2FA will be deactivated, and your account will be vulnerable to hackers.'),
            okText: t('OK'),
            cancelText: t('Cancel'),
            onOk: (() => setIsDeactivating(true))
        });
    };
    const getBarcode = async ()=>{
        try {
            setLoading(true);
            const result = await GenerateTFAKey({username: username});
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                message.open({type: 'success', content: result.message});
                setTFAUrl({key:result.data.key, otpAuthUrl:result.data.otpAuthUrl} as IGeneratedTFAUrlKeyType);
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    }
    const onFinish = async (values: ITFAActivationFornmType) => {
        try {
            setLoading(true);
            values.username = username;
            values.key = tfaUrl.key;
            const result = await TFAActivateNow(values);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                setIsActualTFAActive(true);
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };
    const onFinishDeactive = async (values: ITFADeactivationFornmType) => {
        try {
            setLoading(true);
            values.username = username;
            const result = await TFADeactivateNow(values);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                setIsActualTFAActive(false);
                setTFAUrl({key:'', otpAuthUrl:''} as IGeneratedTFAUrlKeyType);
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Row {...stylesContext?.rowProps}>
            <Col xs={24} lg={24}>
                {!isActualTFAActive ? (
                    <>
                        <h2 style={{color: "red"}}>{t('2FA is currently inactive')}</h2>
                        {tfaUrl.key === '' ? (
                            <Button type="primary" onClick={handleActivationButton}>
                            {t('Activate Now')}
                            </Button>
                            ) :
                            (
                                <>
                                    <h3>{t('Please scan the following QR code using Google Authenticator. Enter the 6-digit token that appears.')}</h3>
                                    <QRCode value={tfaUrl.otpAuthUrl} size={300}/>
                                    <Form
                                        form={form}
                                        name="role"
                                        layout="vertical"
                                        labelCol={{span: 24}}
                                        wrapperCol={{span: 24}}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        autoComplete="off"
                                        requiredMark={false}
                                    >
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} lg={24}>
                                                <Form.Item label={t("2FA Authentication")} name="token">
                                                    <Input.OTP formatter={(str) => str.toUpperCase()} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} lg={24}>
                                                <span style={{color: "red"}}>* {t('If the entered token is always incorrect, ensure that the time settings on your phone are set to automatic.')}</span>
                                            </Col>
                                        </Row>
                                        <Form.Item>
                                            <Flex align="center" justify="space-between">
                                                <Button type="primary" htmlType="submit" size="middle" loading={loading}> {t('Activate Now')} </Button>
                                            </Flex>
                                        </Form.Item>
                                    </Form>
                                </>
                            )
                        }
                        {contextModalHolder}
                    </>
                ):(
                    <>
                        {!isDeactivating ? (
                            <>
                                <h2 style={{color: "green"}}>{t('2FA Activated')}</h2>
                                <Button type="primary" danger onClick={handleDeactivationButton}>
                                    {t('Deactivate Now')}
                                </Button>
                            </>
                            ): (
                            <>
                                <h2 style={{color: "green"}}>{t('Enter OTP Code')}</h2>
                                <Form
                                    form={form}
                                    name="role"
                                    layout="vertical"
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                    onFinish={onFinishDeactive}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    requiredMark={false}
                                >
                                    <Row gutter={[8, 0]}>
                                        <Col xs={24} lg={24}>
                                            <Form.Item label={t("2FA Authentication")} name="token">
                                                <Input.OTP formatter={(str) => str.toUpperCase()} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item>
                                        <Flex align="center" justify="space-between">
                                            <Button type="primary" htmlType="submit" size="middle" loading={loading}> {t('Deactivate Now')} </Button>
                                        </Flex>
                                    </Form.Item>
                                </Form>
                            </>
                            )
                        }
                        {contextModalHolder}
                    </>
                )}
            </Col>
        </Row>
    );
};

export default TFA;
