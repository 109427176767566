import { Result } from 'antd';
import {BackBtn} from "../../components/BackBtn/BackBtn";
import {RefreshBtn} from "../../components/RefreshBtn/RefreshBtn";
import {useTranslation} from "react-i18next";

export const Error500Page = () => {
  const { t } = useTranslation();
  return (
    <Result
      status="500"
      title="500"
      subTitle={t("Sorry, something went wrong.")}
      extra={[<BackBtn type="primary" />, <RefreshBtn />]}
    />
  );
};
