import { IReturnResponse} from "../../types/AppServiceType";
import {AxiosRequestConfig} from "axios";
import {AllErrorType, client, ResponseChecker, ReturnCatchResponse} from "../AppService";
import {IBankAccountFormType, IBankAccountInUseFormType} from "../../types/master/BankAccountType";
import {toLower} from "lodash";


export const GetListBankAccount = async (moduleType:string) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    return await client.get<IReturnResponse>(`/api/master/bank_account/${toLower(moduleType)}`,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const GetUsedBankAccount = async (moduleType:string) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    return await client.get<IReturnResponse>(`/api/master/bank_account/${toLower(moduleType)}/getused`,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const GetDetailBankAccount = async (moduleType:string, bankAccountId:number = 0) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const url = bankAccountId === 0 ? `/api/master/bank_account/${toLower(moduleType)}/new/` : `/api/master/bank_account/${toLower(moduleType)}/${bankAccountId}`;
    return await client.get<IReturnResponse>(url,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const DeleteBankAccount = async (moduleType:string, bankAccountId:number) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',

        },
    };
    return await client.delete<IReturnResponse>(`/api/master/bank_account/${toLower(moduleType)}/${bankAccountId}`,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const SaveBankAccount = async (moduleType:string, bankAccountId:number, formData:IBankAccountFormType) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const url = bankAccountId === 0 ? `/api/master/bank_account/${toLower(moduleType)}/new` : `/api/master/bank_account/${toLower(moduleType)}/${bankAccountId}`;
    return await client.post<IReturnResponse>( url,formData, config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const SetUseBankAccount = async (moduleType:string, bankAccountId:number, inUse:boolean) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const formData = {inUse:inUse} as IBankAccountInUseFormType
    const url = `/api/master/bank_account/${toLower(moduleType)}/use/${bankAccountId}`;
    return await client.post<IReturnResponse>( url,formData, config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}

export const GetLookupBankAccountList = async (moduleType:string) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const url = `/api/master/bank_account/${toLower(moduleType)}/lookup`;
    return await client.get<IReturnResponse>( url, config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
