import React, {useCallback, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {ITEMS_TRANSACTION_COINFLOW,} from "../../../routes/ConfigRoutes";
import {useStylesContext} from "../../../context/StylesContext";
import {
    Button,
    Col,
    DatePicker,
    Flex,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Select,
    Tooltip
} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {Card} from "../../../components/Card/Card";
import {PageHeader} from "../../../components/PageHeader/PageHeader";
import {useTranslation} from "react-i18next";
import {StatusCode} from "../../../service/AppService";
import {AppName} from "../../../config/Config";
import {ToNumber} from "../../../helper/Helper";
import {IMemberListType} from "../../../types/master/MemberType";
import {debounce, isEmpty} from "lodash";
import {PlusOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {ICoinFlowFormType, ICoinFlowResponseData} from "../../../types/transaction/CoinFlowType";
import {GetDetailCoinFlow, SaveCoinFlow } from "../../../service/transaction/CoinFlowServices";
import {SearchMember} from "../../../service/master/MemberServices";
import {FlexCustom} from "../../../components/Flex/FlexCustom";
import CheckAcess from "../../../routes/Helper/CheckAcess";

const CoinFlowEditor = () => {
    const { t } = useTranslation();
    const { coinflowIdParam } = useParams<{ coinflowIdParam: string }>();
    const navigate = useNavigate();
    const stylesContext = useStylesContext();
    const [coinflowId, setDpwdId] = useState(ToNumber(coinflowIdParam));
    const [coinflowNumber, setDpwdNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [coinflowTypeList, setCoinflowTypeList] = useState([] as string[])
    const [memberList, setMemberList] = useState([] as IMemberListType[])
    const [form] = Form.useForm<ICoinFlowFormType>();
    const [loadingSeachUser, setLoadingSeachUser] = useState<boolean>(false);
    const [needUser, setNeedUser] = useState<boolean>(true);

    const { Option } = Select;
    const title = (
        <FlexCustom justifyContent={"space-between"}>
            <div>{coinflowId === 0 ? t('NewFor', {field:'COIN'}) : coinflowNumber}</div>
            <FlexCustom justifyContent="end">
                <Tooltip title={t("New Record")}>
                    <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={()=>navigate('/transaction/coinflow/new')} />
                </Tooltip>
            </FlexCustom>
        </FlexCustom>
    )
    const onFinish = async (values: ICoinFlowFormType) => {
        try {
            setLoading(true);
            if(coinflowId !== 0) if(!CheckAcess('Coin.Edit')) return;
            else if(coinflowId === 0) if(!CheckAcess('Coin.New')) return;
            const dateValue = dayjs(values.coinflowDate);
            const formattedValues = {
                ...values,
                coinflowDate: dateValue.format('YYYY-MM-DD HH:mm:ss'),
            };
            const result = await SaveCoinFlow(coinflowId, formattedValues);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data as ICoinFlowResponseData;
                SetFormReload(data);

                message.open({type: 'success', content: result.message});
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };
    const SetFormReload = (data :ICoinFlowResponseData) => {
        if(Object.values(data.data).length > 0) {

            form.setFieldsValue({
                coinflowId: data.data.coinflowId,
                coinflowNumber: data.data.coinflowNumber,
                coinflowDate: isEmpty(data.data.coinflowDate) ? '' :dayjs(data.data.coinflowDate),
                coinflowType: data.data.coinflowType,
                memberId: data.data.memberId,
                amount: data.data.amount,
                refNumber: data.data.refNumber,
                description: data.data.description,
            });

            setDpwdId(ToNumber(data.data.coinflowId));
            setDpwdNumber(data.data.coinflowNumber);

            // khusus untuk select list yang ajax harus set list nya
            setMemberList([{memberId: data.data.memberId, userId:data.data.userId}] as IMemberListType[])
        }
        setCoinflowTypeList(data.coinflowTypeList)
    }
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const ReloadData = async () => {
        try {
            setLoading(true);
            const result = await GetDetailCoinFlow(coinflowId);

            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data as ICoinFlowResponseData;
                console.log(data);
                SetFormReload(data);
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };
    // const debounceFetchOptions = useCallback(debounce(handleSearchMember, 300), []);
    const debounceFetchOptions = useCallback(debounce((value: string) => handleSearchMember(value), 300), []);

    const handleSearchMember = async (userId: string) => {
        try {
            if (userId.length < 3) {
                setMemberList([]);
                return;
            }
            setLoadingSeachUser(true);
            console.log(coinflowId)
            const result = await SearchMember(userId);

            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data;
                setMemberList(data);
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoadingSeachUser(false);
        }
    };
    const handleSearchMemberInit = (value: string) => {
        debounceFetchOptions(value);
    };

    const handleChangeTransType = (value:string) => {
        if(value === "ADD COIN") setNeedUser(true);
        else setNeedUser(false);
    }

    useEffect(() => {
        ReloadData();
    }, []);



    return (
        <div>
            <Helmet>
                <title>COIN| {AppName}</title>
            </Helmet>
            <Flex vertical gap="middle">
                <PageHeader title="COIN" breadcrumbs={ITEMS_TRANSACTION_COINFLOW}/>
                <Row {...stylesContext?.rowProps}>
                    <Col xs={24} lg={24}>
                        <Card title={title}>
                            <Form
                                form={form}
                                name="bank"
                                layout="vertical"
                                initialValues={{isActive:true, coinflowDate: dayjs(), amount:0, rateCoin:100}}
                                labelCol={{span: 24}}
                                wrapperCol={{span: 24}}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                requiredMark={true}
                                size={"small"}
                            >

                                <Row gutter={[8, 0]}>
                                    <Col xs={24} lg={24}>
                                        <Form.Item label="Transaction Number" name="coinflowNumber" hidden>
                                            <Input disabled={true} placeholder="Automatic Number"/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]}>
                                    <Col md={24} lg={24}>
                                        <Card title={t('Transaction')}>
                                            <Row gutter={[8, 0]}>
                                                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                                                    <Form.Item label="Transaction Date" name="coinflowDate"
                                                        rules={[
                                                            {required: true, message: t('Cannot be empty')},
                                                        ]}
                                                    >
                                                        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{width:'100%'}}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                                                    <Form.Item name="coinflowType" label="Transaction Type" rules={[{ required: true }]}>
                                                        <Select placeholder="Select a Type" showSearch allowClear onChange={handleChangeTransType} >
                                                            {
                                                                coinflowTypeList.map((x,idx)=> (
                                                                    <Option value={x} key={idx}>{x}</Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                                                    <Form.Item name="amount" label="Amount"
                                                        rules={[
                                                            {required: true, message: t('Cannot be empty')},
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            style={{ width: '100%' }}
                                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                                                            placeholder="Enter Amount"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                {needUser ? '' :
                                                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                                                    <Form.Item label="User ID" name="memberId"
                                                        rules={[
                                                            {required: true, message: t('Cannot be empty')}
                                                        ]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            placeholder={t('Enter User Id to search')}
                                                            defaultActiveFirstOption={false}
                                                            suffixIcon={null}
                                                            filterOption={false}
                                                            onSearch={handleSearchMemberInit}
                                                            notFoundContent={loadingSeachUser ? 'Loading...' : 'No results found'}
                                                            options={(memberList || []).map((d) => ({
                                                                value: d.memberId,
                                                                label: d.userId,
                                                            }))}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                }
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col xs={24}>
                                        <Card title={t('Others Informations')}>
                                            <Row gutter={[8, 0]}>
                                                <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
                                                    <Form.Item label="Reference Number" name="refNumber">
                                                        <Input placeholder="Enter Reference Number if exists"/>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
                                                    <Form.Item label="Descriptions / Informations" name="description">
                                                        <Input placeholder="Enter Reference Number if exists"/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                                <Form.Item>
                                    <Flex align="center" justify="space-between">
                                        <Button type="primary" htmlType="submit" size="middle" loading={loading}> {t('Save')} </Button>
                                    </Flex> </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Flex>
        </div>
    );
};

export default CoinFlowEditor;
