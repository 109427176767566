import { IReturnResponse} from "../../types/AppServiceType";
import {AxiosRequestConfig} from "axios";
import {AllErrorType, client, ResponseChecker, ReturnCatchResponse} from "../AppService";
import {IMemberFormType} from "../../types/master/MemberType";


export const GetListMember= async () : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    return await client.get<IReturnResponse>("/api/master/member",config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const GetDetailMember = async (memberId:number = 0) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const url = memberId === 0 ? `/api/master/member/new/` : `/api/master/member/${memberId}`;
    return await client.get<IReturnResponse>(url,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const DeleteMember = async (memberId:number) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',

        },
    };
    return await client.delete<IReturnResponse>(`/api/master/member/${memberId}`,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const SaveMember = async (memberId:number, formData:IMemberFormType) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const url = memberId === 0 ? `/api/master/member/new` : `/api/master/member/${memberId}`;
    return await client.post<IReturnResponse>( url,formData, config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}

export const SearchMember = async (useridSearch:string) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const url = `/api/master/member/search_list`;
    return await client.post<IReturnResponse>( url, {userId:useridSearch}, config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
