import { IReturnResponse} from "../types/AppServiceType";
import {AxiosRequestConfig} from "axios";
import {AllErrorType, client,  ResponseChecker, ReturnCatchResponse} from "./AppService";

interface IStyleType {
    styleName: string;
    value:string[]
}
export const GetStyle = async (styleName:string) : Promise<IReturnResponse>  => {

    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 30000
    };
    return await client.get<IReturnResponse>(`/api/style/${styleName}`,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const PostStyle = async (styleName:string, value:string[]) : Promise<IReturnResponse>  => {
    const styleData: IStyleType = {
        styleName: styleName,
        value: value
    }
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 30000
    };
    return await client.post<IReturnResponse>(`/api/style/${styleName}`,styleData, config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}

