import { Col, Flex, Row, Typography } from 'antd';
import {useStylesContext} from "../context/StylesContext";
import {
  BugOutlined,
  BulbOutlined,
  CodeOutlined,
  GithubOutlined,
  HomeOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { createElement } from 'react';
import { Link } from 'react-router-dom';
import {ITEMS_ABOUT} from "../routes/ConfigRoutes";
import {Helmet} from "react-helmet-async";
import { Card } from '../components/Card/Card';
import {PageHeader} from "../components/PageHeader/PageHeader";

const { Title, Text } = Typography;

const SITES = [
  {
    title: 'GitHub',
    description: 'Source code of the website.',
    icon: GithubOutlined,
    link: '',
  },
  {
    title: 'Report Bug',
    description: 'Something not working? Report a bug',
    icon: BugOutlined,
    link: '',
  },
  {
    title: 'Request Feature',
    description: 'Need something? Request a new feature.',
    icon: BulbOutlined,
    link: '',
  },
  {
    title: 'Contribute',
    description: 'Contribute to this project.',
    icon: CodeOutlined,
    link: '',
  },
];

export const About = () => {
  const context = useStylesContext();

  return (
    <div>
      <Helmet>
        <title>About | Antd Dashboard</title>
      </Helmet>
      <Flex vertical gap="middle">
        <PageHeader title="About" breadcrumbs={ITEMS_ABOUT}/>
        <Card>
          <Flex vertical gap="small">
            <Title level={3} className="m-0">
              Antd Admin
            </Title>
            <Text>
              A dynamic and versatile multipurpose dashboard template built
              using React, Vite, Ant Design, and Storybook
            </Text>
          </Flex>
        </Card>
        <Row {...context?.rowProps}>
          {SITES.map((s) => (
            <Col xs={24} sm={12} key={`col-${s.title}`}>
              <a href={s.link} target="_blank" rel="noopener noreferrer">
                <Card hoverable>
                  <Flex vertical gap="small">
                    {createElement(s.icon, { style: { fontSize: 24 } })}
                    <Title level={5} className="m-0">
                      {s.title}
                    </Title>
                    <Text>{s.description}</Text>
                  </Flex>
                </Card>
              </a>
            </Col>
          ))}
        </Row>
      </Flex>
    </div>
  );
};
