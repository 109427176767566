import React from 'react';
import { Breadcrumb, BreadcrumbProps, Space, Typography } from 'antd';

import './styles.css';
import {BreadcrumbType, ITEMS_TRANSACTION_CAHSFLOW} from "../../routes/ConfigRoutes";
import {Link} from "react-router-dom";

type Props = {
  title: string;
  breadcrumbs?: BreadcrumbType[];
} & React.HTMLAttributes<HTMLDivElement>;

export const PageHeader = ({ breadcrumbs, title, ...others }: Props) => {
  const breadcrumbList = breadcrumbs ? breadcrumbs.map((d) => ({
    title: (
        <>
            {d.link ?
            <Link to={d.path}>
              <span className={"anticon anticon-profile"}> <d.icon/> </span>
              <span> {d.name} </span>
            </Link>
                :
                <Link to='#'>
                    <span className={"anticon anticon-profile"}> <d.icon/> </span>
                    <span> {d.name} </span>
                </Link>
            }
        </>
    ),
  })) : [];
  return (
    <div {...others}>
      <Space direction="vertical" size="small">
        <Typography.Title
          level={4}
          style={{ padding: 0, margin: 0, textTransform: 'capitalize' }}
        >
          {title}
        </Typography.Title>
        <Breadcrumb items={breadcrumbList} className="page-header-breadcrumbs" />
      </Space>
      {/*<Divider orientation="right" plain>*/}
      {/*  <span style={{ textTransform: 'capitalize' }}>{title}</span>*/}
      {/*</Divider>*/}
    </div>
  );
};
