import React, {useCallback, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {ITEMS_TRANSACTION_DPWD,} from "../../../routes/ConfigRoutes";
import {useStylesContext} from "../../../context/StylesContext";
import {
    Button,
    Col,
    DatePicker,
    Flex,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Select,
    Space,
    Tooltip
} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {Card} from "../../../components/Card/Card";
import {PageHeader} from "../../../components/PageHeader/PageHeader";
import {useTranslation} from "react-i18next";
import {StatusCode} from "../../../service/AppService";
import {AppName} from "../../../config/Config";
import {ToNumber} from "../../../helper/Helper";
import {IBankAccountListType, IBankListType} from "../../../types/master/BankAccountType";
import {IMemberListType} from "../../../types/master/MemberType";
import {SearchMember} from "../../../service/master/MemberServices";
import {IDPWDFormType, IDPWDResponseData} from "../../../types/transaction/DPWDType";
import {GetDetailDPWD, SaveDPWD} from "../../../service/transaction/DPWDServices";
import {debounce, isEmpty} from "lodash";
import {PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {GetLookupBankAccountList} from "../../../service/master/BankAccountServices";
import dayjs from "dayjs";
import {FlexCustom} from "../../../components/Flex/FlexCustom";
import CheckAcess from "../../../routes/Helper/CheckAcess";

const DPWDEditor = () => {
    const { t } = useTranslation();
    const { dpwdIdParam } = useParams<{ dpwdIdParam: string }>();
    const navigate = useNavigate();
    const stylesContext = useStylesContext();
    const [dpwdId, setDpwdId] = useState(ToNumber(dpwdIdParam));
    const [dpwdNumber, setDpwdNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [bankList, setBankList] = useState([] as IBankListType[])
    const [bankAccountList, setBankAccountList] = useState([] as IBankAccountListType[])
    const [transTypeList, setTransTypeList] = useState([] as string[])
    const [memberList, setMemberList] = useState([] as IMemberListType[])
    const [form] = Form.useForm<IDPWDFormType>();
    const [loadingSeachUser, setLoadingSeachUser] = useState<boolean>(false);
    const [loadingBankAccountList, setLoadingBankAccountList] = useState<boolean>(false);
    const [needBankAccount, setNeedBankAccount] = useState(true);
    const [useRate, setUseRate] = useState(true);

    const { Option } = Select;
    const title = (
        <FlexCustom justifyContent={"space-between"}>
            <div>{dpwdId === 0 ? t('NewFor', {field:'Deposit or Withdraw'}) : dpwdNumber}</div>
            <FlexCustom justifyContent="end">
                <Tooltip title={t("New Record")}>
                    <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={()=>navigate('/transaction/dpwd/new')} />
                </Tooltip>
            </FlexCustom>
        </FlexCustom>
    )
    const onFinish = async (values: IDPWDFormType) => {
        try {
            setLoading(true);
            if(dpwdId !== 0) if(!CheckAcess('DPWD.Edit')) return;
            else if(dpwdId === 0) if(!CheckAcess('DPWD.New')) return;
            const dateValue = dayjs(values.dpwdDate);
            const formattedValues = {
                ...values,
                 dpwdDate: dateValue.format('YYYY-MM-DD HH:mm:ss'),
            };
            const result = await SaveDPWD(dpwdId, formattedValues);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data as IDPWDResponseData;
                SetFormReload(data);

                message.open({type: 'success', content: result.message});
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };
    const SetFormReload = (data :IDPWDResponseData) => {
        if(Object.values(data.data).length > 0) {

            form.setFieldsValue({
                dpwdId: data.data.dpwdId,
                dpwdNumber: data.data.dpwdNumber,
                dpwdDate: isEmpty(data.data.dpwdDate) ? '' :dayjs(data.data.dpwdDate),
                transType: data.data.transType,
                transBankAccountId: data.data.transBankAccountId,
                memberId: data.data.memberId,
                memberBank: data.data.memberBank,
                memberBankAccountNumber: data.data.memberBankAccountNumber,
                memberBankAccountName: data.data.memberBankAccountName,
                amount: data.data.amount,
                rateCoin: data.data.rateCoin,
                refNumber: data.data.refNumber,
                description: data.data.description,
            });

            setDpwdId(ToNumber(data.data.dpwdId));
            setDpwdNumber(data.data.dpwdNumber);

            // khusus untuk select list yang ajax harus set list nya
            setMemberList([{memberId: data.data.memberId, userId:data.data.userId}] as IMemberListType[])
        }
        setBankList(data.bankList);
        setTransTypeList(data.transTypeList)
        setBankAccountList(data.bankAccountList);
    }
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const ReloadData = async () => {
        try {
            setLoading(true);
            const result = await GetDetailDPWD(dpwdId);

            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data as IDPWDResponseData;
                SetFormReload(data);
                transTypeChanged(data.data.transType);
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };
    const ReloadBankAccount = async () => {
        try {
            setLoadingBankAccountList(true);
            const result = await GetLookupBankAccountList('DPWD');

            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data;
                setBankAccountList(data);
                const filteredBanks = bankAccountList.filter(bank => bank.useByMe);
                if(filteredBanks.length > 0) form.setFieldValue('transBankAccountId', filteredBanks[0].bankAccountId);
                console.log(filteredBanks)
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoadingBankAccountList(false);
        }
    };
    // const debounceFetchOptions = useCallback(debounce(handleSearchMember, 300), []);
    const debounceFetchOptions = useCallback(debounce((value: string) => handleSearchMember(value), 300), []);

    const handleSearchMember = async (userId: string) => {
        try {
            if (userId.length < 3) {
                setMemberList([]);
                return;
            }
            setLoadingSeachUser(true);
            console.log(dpwdId)
            const result = await SearchMember(userId);

            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data;
                setMemberList(data);
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoadingSeachUser(false);
        }
    };
    const handleSearchMemberInit = (value: string) => {
        debounceFetchOptions(value);
    };
    const handleChangeMember = async (memberId: number) => {
        const memberSelected = memberList.filter(member => member.memberId === memberId);
        if(memberSelected.length > 0) {
            form.setFieldValue('memberBank', memberSelected[0].bank);
            form.setFieldValue('memberBankAccountNumber', memberSelected[0].accountNumber);
            form.setFieldValue('memberBankAccountName', memberSelected[0].accountName);
        }
    };
    const handleChangeTransBankAccount = async (transBankAccountId: number) => {
        form.setFieldValue('transBankAccountId', transBankAccountId);
        const bankAccountSelected = bankAccountList.filter(bankAccount => bankAccount.bankAccountId === transBankAccountId);
        if(bankAccountSelected.length > 0) {
            form.setFieldValue('rateCoin', bankAccountSelected[0].defaultCoinRate);
            if(bankAccountSelected[0].bankType === 'PULSA') setUseRate(true);
            else setUseRate(false);
        }
    };

    useEffect(() => {
        ReloadData();
    }, []);

    const transTypeChanged = (val:string) => {
        if (val === 'MISTAKE DEPOSIT') setNeedBankAccount(false);
        else {
            form.setFieldValue('transBankAccountId', null);
            setNeedBankAccount(true);
        }
    }


  return (
    <div>
      <Helmet>
        <title>Deposit and Withdraw | {AppName}</title>
      </Helmet>
        <Flex vertical gap="middle">
            <PageHeader title="Deposit and Withdraw" breadcrumbs={ITEMS_TRANSACTION_DPWD} />
            <Row {...stylesContext?.rowProps}>
                <Col xs={24} lg={24}>
                    <Card title={title}>
                        <Form
                            form={form}
                            name="bank"
                            layout="vertical"
                            initialValues={{isActive:true, dpwdDate: dayjs(), amount:0, rateCoin:100}}
                            labelCol={{span: 24}}
                            wrapperCol={{span: 24}}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            requiredMark={true}
                            size={"small"}
                        >

                            <Row gutter={[8, 0]}>
                                <Col xs={24} lg={24}>
                                    <Form.Item label="Transaction Number" name="dpwdNumber" hidden>
                                        <Input disabled={true} placeholder="Automatic Number"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[8, 0]}>
                                <Col md={24} lg={12}>
                                    <Card title={t('Transaction')}>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
                                                <Form.Item label="Transaction Date" name="dpwdDate"
                                                    rules={[
                                                        {required: true, message: t('Cannot be empty')},
                                                    ]}
                                                >
                                                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{width:'100%'}}/>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
                                                <Form.Item name="transType" label="Transaction Type" rules={[{ required: true }]}>
                                                    <Select placeholder="Select a Type" showSearch allowClear onChange={transTypeChanged}>
                                                        {
                                                            transTypeList.map((x,idx)=> (
                                                                <Option value={x} key={idx}>{x}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12} hidden={!needBankAccount}>
                                                <Form.Item name="transBankAccountId" label="Bank Account" rules={[{ required: needBankAccount }]} hidden={!needBankAccount}>
                                                    <Space.Compact style={{ width: '100%' }}>
                                                        <Select placeholder={t('Select a Bank Account')}
                                                            style={{ width: 'calc(100% - 30px)'}}
                                                            showSearch
                                                            allowClear
                                                            loading={loadingBankAccountList}
                                                            value={form.getFieldValue('transBankAccountId')}
                                                            onChange={handleChangeTransBankAccount}>
                                                            {/*onChange={(val) => form.setFieldValue('transBankAccountId', val)}>*/}
                                                            {
                                                                bankAccountList.map((x,idx)=> (
                                                                    <Option value={x.bankAccountId} key={idx} disabled={x.inUse}>{x.bankAccountCode} {x.inUse?'(' + t('locked by', {username: x.useBy}) + ')':''}</Option>
                                                                ))
                                                            }
                                                        </Select>
                                                        <Button onClick={ReloadBankAccount}><ReloadOutlined /></Button>
                                                    </Space.Compact>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
                                                <Form.Item label="Amount" style={{margin:'0'}}>
                                                    <Space.Compact style={{ width: '100%' }}>
                                                        <Form.Item name="amount"
                                                            style={{ width: 'calc(100% - 67px)' }}
                                                            rules={[
                                                                {required: true, message: t('Cannot be empty')},
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                style={{ width: '100%' }}
                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                                                                placeholder="Enter Amount"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="rateCoin"
                                                            style={{ width: '67px' }}
                                                            rules={[
                                                                {required: true, message: t('Cannot be empty')},
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                style={{ width: '100%' }}
                                                                formatter={(value) => `${value}%`}
                                                                parser={(value) => value?.replace('%', '') as unknown as number}
                                                                placeholder="Rate"
                                                                disabled={!useRate}
                                                            />
                                                        </Form.Item>
                                                    </Space.Compact>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col md={24} lg={12}>
                                    <Card title={t('Member')}>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
                                                <Form.Item label="User ID" name="memberId"
                                                    rules={[
                                                        {required: true, message: t('Cannot be empty')}
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder={t('Enter User Id to search')}
                                                        defaultActiveFirstOption={false}
                                                        suffixIcon={null}
                                                        filterOption={false}
                                                        onSearch={handleSearchMemberInit}
                                                        onChange={handleChangeMember}
                                                        notFoundContent={loadingSeachUser ? 'Loading...' : 'No results found'}
                                                        options={(memberList || []).map((d) => ({
                                                            value: d.memberId,
                                                            label: d.userId,
                                                        }))}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
                                                <Form.Item name="memberBank" label="Member Bank" rules={[{ required: true }]}>
                                                    <Select
                                                        placeholder="Select a Bank"
                                                        // onChange={onGenderChange}
                                                        allowClear
                                                    >
                                                        {
                                                            bankList.map((x,idx)=> (
                                                                <Option value={x.bank} key={idx}>{x.bank}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
                                                <Form.Item label="Bank Account Number" name="memberBankAccountNumber"
                                                    rules={[
                                                        {required: true, message: t('Cannot be empty')},
                                                        {min: 3, message: t('Minimum character length is', {length: '3'})},
                                                        {max: 100, message: t('Maximum character length is', {length: '100'})}
                                                    ]}
                                                >
                                                    <Input placeholder="Enter Bank Account Number"/>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
                                                <Form.Item label="Bank Account Name" name="memberBankAccountName"
                                                    rules={[
                                                        {required: true, message: t('Cannot be empty')},
                                                        {min: 3, message: t('Minimum character length is', {length: '3'})},
                                                        {max: 100, message: t('Maximum character length is', {length: '100'})}
                                                    ]}
                                                >
                                                    <Input placeholder="Enter Bank Account Name"/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xs={24}>
                                    <Card title={t('Others Informations')}>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
                                                <Form.Item label="Reference Number" name="refNumber">
                                                    <Input placeholder="Enter Reference Number if exists"/>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
                                                <Form.Item label="Descriptions / Informations" name="description">
                                                    <Input placeholder="Enter Reference Number if exists"/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Flex align="center" justify="space-between">
                                    <Button type="primary" htmlType="submit" size="middle" loading={loading}> {t('Save')} </Button>
                                </Flex> </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Flex>
    </div>
  );
};

export default DPWDEditor;
