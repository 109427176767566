import {useLoginContext} from "../../context/LoginProvider";
import {Navigate, useNavigate} from "react-router-dom";
import React, {ReactNode, useEffect} from "react";
import {GetListRole} from "../../service/RoleServices";
import {StatusCode} from "../../service/AppService";
import {message} from "antd";
import {GetAccess, GetCheckAccess} from "../../service/UserServices";

export type ProtectedProps = {
    children: ReactNode;
    accessCode?:string;
};

const NavigateBack: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(-1); // Arahkan ke rute sebelumnya
    }, [navigate]);

    return null; // Tidak perlu render apa-apa
};

const ProtectedRoute = ({ children, accessCode = '' }: ProtectedProps) => {
    const { loggedIn} = useLoginContext();
    const navigate = useNavigate();

    // console.log(loggedIn.isLoggedIn);
    if (!loggedIn.isLoggedIn) {
        return <Navigate to="/login" replace />;
    }

    if(accessCode !== ''){
        const result = GetCheckAccess(accessCode);
        if(result) {
            return <>{children}</>;
        }
        else return <NavigateBack />;
    }
    else return <>{children}</>;

};

export default ProtectedRoute;
