import React, {CSSProperties, ReactNode, useEffect, useRef, useState} from "react";
import {
    ConfigProvider,
    Table,
    TableColumnsType,
} from "antd";

import {StatusCode} from "../../../service/AppService";
import {GetCurrentSummary, GetListClosing} from "../../../service/utility/ClosingServices";
import {useTranslation} from "react-i18next";
import './ClosingCurrentSummary.css';
import {ISummaryBankAccount, ISummaryTransType} from "../../../types/utility/ClosingType"; // Impor gaya kustom

type Props = {
    dataSourceIn:ISummaryBankAccount[]
};
const ClosingCurrentSummaryBankAccount = ({ dataSourceIn }: Props)  => {
    const { t } = useTranslation();
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState<ISummaryBankAccount[]>([]);
    const columns: TableColumnsType<ISummaryBankAccount> = [
        {title: t('Bank Account'), key:'bankAccount', children: [
            {title: t('Code'), dataIndex: 'bankAccountCode', key: 'bankAccountCode', ellipsis: true},
            {title: t('Name'), dataIndex: 'bankAccountName', key: 'bankAccountName', ellipsis: true},
            {title: t('Number'), dataIndex: 'bankAccountNumber', key: 'bankAccountNumber', ellipsis: true}
        ]},
        {title: t('Begin'), dataIndex: 'beginBalance', key: 'beginBalance', align: 'center',
            render: (text) => {
                const formattedText = text % 1 === 0
                    ? new Intl.NumberFormat('en-US').format(text)
                    : new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(text);
                return formattedText;
            }
        },
        {title: t('End'), dataIndex: 'endBalance', key: 'endBalance', align: 'center',
            render: (text) => {
                const formattedText = text % 1 === 0
                    ? new Intl.NumberFormat('en-US').format(text)
                    : new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(text);
                return formattedText;
            }
        }
    ];

    useEffect(() => {
        setDataSource(dataSourceIn);
    }, [dataSourceIn]);

    const rowClassName = (record: ISummaryBankAccount, index: number): string => {
        return (index % 2 === 0 ? 'even-row ' : 'odd-row ');
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        cellFontSizeSM: 11,
                    },
                },
            }}
        >
        <Table rowKey="bankAccountCode"
            pagination={false}
            bordered
            columns={columns}
            dataSource={dataSource}
            loading={tableLoading}
            rowClassName={rowClassName}
            size="small" />
        </ConfigProvider>
  );
};

export default ClosingCurrentSummaryBankAccount;
