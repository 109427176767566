import { IReturnResponse} from "../types/AppServiceType";
import {ILoginFormType, ILoginTokenFormType} from "../types/LoginType";
import {AxiosRequestConfig} from "axios";
import {AllErrorType, client, ResponseChecker, ReturnCatchResponse} from "./AppService";


export const SubmitLogin = async (formData:ILoginFormType) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept-Language': localStorage.getItem('language') ?? 'english'
        },
        timeout: 5000
    };
    return await client.post<IReturnResponse>("/api/login",formData,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return result;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const SubmitTFAToken = async (formData:ILoginTokenFormType) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 5000
    };
    return await client.post<IReturnResponse>("/api/login/token",formData,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return result;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
