import React, {useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet-async";
import {useStylesContext} from "../../../context/StylesContext";
import {
    Button,
    Checkbox,
    Col,
    Flex,
    Input,
    InputRef,
    message,
    Modal,
    Row,
    Space,
    Table,
    TableColumnsType,
    Tooltip
} from "antd";
import {useNavigate} from "react-router-dom";
import {Card} from "../../../components/Card/Card";
import {PageHeader} from "../../../components/PageHeader/PageHeader";
import {
    DeleteOutlined,
    EditOutlined, FilterOutlined,
    PlusOutlined,
    ProjectOutlined,
    SearchOutlined,
    SortAscendingOutlined
} from "@ant-design/icons";
import {FlexCustom} from "../../../components/Flex/FlexCustom";
import {GetStyle, PostStyle} from "../../../service/UserStyleServices";
import {ColumnType} from "antd/es/table";
import {FilterConfirmProps} from "antd/es/table/interface";
import {useTranslation} from "react-i18next";
import {AppName} from "../../../config/Config";
import {StatusCode} from "../../../service/AppService";
import {DeleteCoinFlow, GetListCoinFlow} from "../../../service/transaction/CoinFlowServices";
import {ITEMS_TRANSACTION_COINFLOW} from "../../../routes/ConfigRoutes";
import CheckAcess from "../../../routes/Helper/CheckAcess";

interface DataType {
    coinflowId:number
    coinflowNumber:string
    coinflowDate:string
    coinflowType:string
    transBankAccountId:number
    transBankAccountCode:string
    memberId:number
    userId:string
    amount:number
    refNumber:string
    description:string
    createdBy:string
    createdDate:string
    modifiedBy:string
    modifiedDate:string
}

const styleName = "CoinFlowColumns";
const excludedKeys = ['createdBy', 'createdDate', 'modifiedBy', 'modifiedDate'];

const CoinFlow = () => {
    const { t } = useTranslation();
    const stylesContext = useStylesContext();
    const navigate = useNavigate();
    const [openModalColumn, setOpenModalColumn] = useState(false);

    const [searchedColumn, setSearchedColumn] = useState<DataType>();
    const searchInput = useRef<InputRef>(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState<DataType[]>([]);
    const [sortingEnabled, setSortingEnabled] = useState(false);
    const [searchingEnabled, setSearchingEnabled] = useState(false);
    type DataIndex = keyof DataType;


    const handleSearchs = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchedColumn(dataIndex as any);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
    };
    const getColumnSearchProps = (dataIndex: keyof DataType): ColumnType<DataType> => ({
        filterDropdown: searchingEnabled ? ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={t('SearchField', {field: String(dataIndex)})}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearchs(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearchs(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                        {t('Search')}
                    </Button>
                    <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('Reset')}
                    </Button>
                    <Button type="link" size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchedColumn(dataIndex as any);
                        }} >
                        Filter
                    </Button>
                    <Button type="link" size="small"
                        onClick={() => {
                            close();
                        }}>
                        close
                    </Button>
                </Space>
            </div>
        ) : undefined,
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            String(record[dataIndex])
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible && searchInput.current) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex as any ? (
                <span>
          {text}
        </span>
            ) : (
                text
            ),
    });
    const columns: TableColumnsType<DataType> = [
        // {title: t('coinflowId'), dataIndex: 'coinflowId', key: 'coinflowId', sorter: sortingEnabled ?  (a, b) => a.coinflowId - b.coinflowId : undefined, ellipsis: true, ...getColumnSearchProps('coinflowId')},
        {title: t('Transaction Number'), dataIndex: 'coinflowNumber', key: 'coinflowNumber', fixed: 'left', sorter: sortingEnabled ? (a, b) => a.coinflowNumber.localeCompare(b.coinflowNumber) : undefined, ellipsis: true, ...getColumnSearchProps('coinflowNumber')},
        {
            title: t('Transaction'),
            key: 'transactionGrouping',
            children: [
                {title: t('Date'), dataIndex: 'coinflowDate', key: 'coinflowDate', sorter: sortingEnabled ? (a, b) => a.coinflowDate.localeCompare(b.coinflowDate) : undefined, ellipsis: true, ...getColumnSearchProps('coinflowDate')},
                {title: t('Type'), dataIndex: 'coinflowType', key: 'coinflowType', sorter: sortingEnabled ? (a, b) => a.coinflowType.localeCompare(b.coinflowType) : undefined, ellipsis: true, ...getColumnSearchProps('coinflowType')},
                {title: t('Amount'), dataIndex: 'amount', key: 'amount', sorter: sortingEnabled ?  (a, b) => a.amount - b.amount : undefined, ellipsis: true, ...getColumnSearchProps('amount'),
                    render: (text) => {
                        const formattedText = text % 1 === 0
                            ? new Intl.NumberFormat('en-US').format(text)
                            : new Intl.NumberFormat('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(text);
                        return formattedText;
                    }
                },
                {
                    title: t('Member Id'),
                    dataIndex: 'userId',
                    key: 'userId',
                    sorter: sortingEnabled ? (a, b) => a.userId.localeCompare(b.userId) : undefined,
                    ellipsis: true, ...getColumnSearchProps('userId')
                },
            ]
        },
        // {title: t('Member Id'), dataIndex: 'memberId', key: 'memberId', sorter: sortingEnabled ?  (a, b) => a.memberId - b.memberId : undefined, ellipsis: true, ...getColumnSearchProps('memberId')},
        {title: t('Reference'), dataIndex: 'refNumber', key: 'refNumber', sorter: sortingEnabled ? (a, b) => a.refNumber.localeCompare(b.refNumber) : undefined, ellipsis: true, ...getColumnSearchProps('refNumber')},
        {title: t('Descriptions'), dataIndex: 'description', key: 'description', sorter: sortingEnabled ? (a, b) => a.description.localeCompare(b.description) : undefined, ellipsis: true, ...getColumnSearchProps('description')},
        {title: t('Created By'), dataIndex: 'createdBy', key: 'createdBy', sorter: sortingEnabled ? (a, b) => a.createdBy.localeCompare(b.createdBy) : undefined, ellipsis: true, ...getColumnSearchProps('createdBy')},
        {title: t('Created Date'), dataIndex: 'createdDate', key: 'createdDate', sorter: sortingEnabled ? (a, b) => a.createdDate.localeCompare(b.createdDate) : undefined, ellipsis: true, ...getColumnSearchProps('createdDate')},
        {title: t('Modified By'), dataIndex: 'modifiedBy', key: 'modifiedBy', sorter: sortingEnabled ? (a, b) => a.modifiedBy.localeCompare(b.modifiedBy) : undefined, ellipsis: true, ...getColumnSearchProps('modifiedBy')},
        {title: t('Modified Date'), dataIndex: 'modifiedDate', key: 'modifiedDate', sorter: sortingEnabled ? (a, b) => a.modifiedDate.localeCompare(b.modifiedDate) : undefined, ellipsis: true, ...getColumnSearchProps('modifiedDate')},
        {
            title: 'Actions',
            dataIndex: 'actions',
            width: 100,
            key: 'actions',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">
                    <Tooltip title={t("Edit Record")}>
                        <Button onClick={() => handleEdit(record.coinflowId)} shape="circle"><EditOutlined /></Button>
                    </Tooltip>
                    <Tooltip title={t("Delete Record")}>
                        <Button onClick={() => handleDelete(record.coinflowId, record.coinflowNumber)} shape="circle" type='primary' danger><DeleteOutlined /></Button>
                    </Tooltip>
                </Space>
            ),
        },
    ];
    const handleEdit = (coinflowId:number) => {
        // Logika untuk meng-handle edit
        if(CheckAcess('Coin.View'))  navigate(`/transaction/coinflow/${coinflowId}`);
    };
    const handleNew = () => {
        // Logika untuk meng-handle edit
        if(CheckAcess('Coin.New')) navigate('/transaction/coinflow/new')
    };

    const handleDelete = (coinflowId:number, coinflowNumber:string) => {
        // Logika untuk meng-handle delete
        if(!CheckAcess('Coin.Delete')) return;
        Modal.confirm({
            type: 'warning',
            title: t('Warning'),
            content: t('Are you sure want to delete', {field:coinflowNumber}),
            cancelText: t('Cancel'),
            onOk() {
                DeleteData(coinflowId);
            },
            onCancel() {
                message.open({type: 'info', content: t('Cancel Delete')});
            },
        });
    };

    const ReloadList = async () => {
        try {
            setTableLoading(true);
            await ReloadStyle();
            const result = await GetListCoinFlow();
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                setDataSource(result.data);
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setTableLoading(false);
        }
    };
    const HandleDeleteRow = (coinflowId:number) => {
        const newData = dataSource.filter((item) => item.coinflowId !== coinflowId);
        setDataSource(newData);
    };
    const DeleteData = async (coinflowId:number) => {
        try {
            setTableLoading(true);
            const result = await DeleteCoinFlow(coinflowId);
            if(result.status === StatusCode.Code200_OK && result.success && result.message !== undefined) {
                setDataSource(result.data);
                HandleDeleteRow(coinflowId);
                message.open({type: 'success', content: result.message});
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setTableLoading(false);
        }
    };

    useEffect(() => {
        ReloadList();
    }, []);


    const defaultCheckedList = columns.map((item) => item.key as string).filter((key) => !excludedKeys.includes(key));
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const newColumns = columns.map((item) => ({
        ...item,
        hidden: !checkedList.includes(item.key as string),
    }));
    const SaveStyle = async () => {
        try {
            const result = await PostStyle(styleName, checkedList);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                message.open({type: 'success', content: result.message});
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
        }
    }
    const ChangedCheckedList = (value:string[]) => {
        console.log(value);
        setCheckedList(value);
    }
    const ReloadStyle = async () => {
        try {
            const result = await GetStyle(styleName);
            if(result.status === StatusCode.Code200_OK && result.success && result.data.length !> 0) {
                setCheckedList(result.data);
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
        }
    }
    // useEffect(() => {
    //     ReloadStyle();
    // }, [columns]);
    return (
        <div>
            <Helmet>
                <title>COIN | {AppName}</title>
            </Helmet>
            <Flex vertical gap="middle">
                <PageHeader title="COIN" breadcrumbs={ITEMS_TRANSACTION_COINFLOW}/>
                <Row {...stylesContext?.rowProps}>
                    <Col xs={24} lg={24}>
                        <Card>
                            <Flex vertical gap="small">
                                <Row>
                                    <Col xs={24} lg={24}>
                                        <FlexCustom justifyContent="end">
                                            <Tooltip title={t("New Record")}>
                                                <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={handleNew} />
                                            </Tooltip>
                                            <Tooltip title={t("Columns")}>
                                                <Button type="primary" shape="circle" icon={<ProjectOutlined />} onClick={()=>setOpenModalColumn(true)} />
                                            </Tooltip>
                                            <Tooltip title={t( (sortingEnabled ? "Disable" : "Enable") + " Sort")}>
                                                <Button type="primary" shape="circle" icon={<SortAscendingOutlined />} onClick={()=>setSortingEnabled(!sortingEnabled)} />
                                            </Tooltip>
                                            <Tooltip title={t( (sortingEnabled ? "Disable" : "Enable") + " Filter")}>
                                                <Button type="primary" shape="circle" icon={<FilterOutlined />} onClick={()=>setSearchingEnabled(!searchingEnabled)} />
                                            </Tooltip>
                                        </FlexCustom>
                                    </Col>
                                </Row>
                                <Table rowKey="coinflowId"
                                    bordered
                                    columns={newColumns}
                                    dataSource={dataSource}
                                    loading={tableLoading}
                                    scroll={{ x: true }}
                                    size="small" />
                                <Modal
                                    title={<p>List Columns</p>}
                                    footer={
                                        <Button type="primary" onClick={SaveStyle}>
                                            Save Template
                                        </Button>
                                    }
                                    open={openModalColumn}
                                    onCancel={()=>setOpenModalColumn(false)}
                                >
                                    <Checkbox.Group
                                        value={checkedList}
                                        onChange={(value) => {
                                            ChangedCheckedList(value as string[]);
                                        }}
                                    >
                                        <Row>
                                            {columns.map(option => (
                                                <Col xs={12} lg={8} key={option.title as string}>
                                                    <Checkbox value={option.key} disabled={option.title==='Actions'}>{option.title as string}</Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Modal>
                            </Flex>
                        </Card>
                    </Col>
                </Row>
            </Flex>
        </div>
    );
};

export default CoinFlow;
