import {Button, Checkbox, Col, Flex, Form, Input, message, Row, Select, theme, Typography} from 'antd';
import { LockOutlined, UserOutlined} from '@ant-design/icons';
import {useMediaQuery} from 'react-responsive';
import {useNavigate} from 'react-router-dom';
import React, {useEffect, useRef, useState} from 'react';
import {useLoginContext} from "../../context/LoginProvider";
import {AppCreator, AppName} from "../../config/Config";
import {SubmitLogin} from "../../service/LoginServices";
import {ILoginFormType} from "../../types/LoginType";
import {StatusCode} from "../../service/AppService";
import {Logo} from "../../components/Logo/Logo";
import TFAModal, {TFAPropsHandles} from "./TFAModal";
import Language from "../component/Language";
import { useTranslation } from 'react-i18next';

const {Title, Text} = Typography;

type FieldType = {
    username?: string;
    password?: string;
    remember?: boolean;
};

function Login() {
    const { t, i18n } = useTranslation();
    const { loggedIn,setLoggedIn } = useLoginContext();
    const { token: {colorPrimary}} = theme.useToken();
    const isMobile = useMediaQuery({maxWidth: 769});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const TFARef = useRef<TFAPropsHandles>(null);
    const TFAOnSuccess = (msg:string) =>{
        message.open({type: 'success', content: msg});
        navigate('/');
    }

    const onFinish = async (values: ILoginFormType) => {
        try {
                setLoading(true);
                const result = await SubmitLogin(values);
                if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                    setLoggedIn({username: result.data.username, isLoggedIn: true, isTFAActive: result.data.isTFAActive, isTFASuccess:false})
                    if(result.data.isTFAActive === 1){
                        if (TFARef.current) {
                            TFARef.current.setOpenModal(true);
                        }
                    }
                    else {
                        message.open({type: 'success', content: result.message});
                        navigate('/');
                    }
                }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(()=>{
    },[loggedIn])

    return (
        <>
            <div style={{position:'fixed', top:'1px', right:'1px', margin:'5px', zIndex:'1000'}}>
                <Language/>
            </div>
            <Row style={{minHeight: isMobile ? 'auto' : '100vh', overflow: 'hidden'}}>
                <Col xs={24} lg={12}>
                    <Flex vertical align="center" justify="center" className="text-center" style={{background: colorPrimary, height: '100%', padding: '1rem'}}>
                        <Logo color="white"/>
                        <Title level={2} className="text-white"> {t("WelcomeTitle", {AppName: AppName})}</Title>
                        <Text className="text-white" style={{fontSize: 18}}> {t("DescriptionWelcome", {creator: AppCreator})} </Text>
                    </Flex>
                </Col>
                <Col xs={24} lg={12}>
                    <Flex vertical align={isMobile ? 'center' : 'flex-start'} justify="center" gap="middle" style={{height: '100%', padding: '2rem'}}>
                        <Title className="m-0">{t('Login')}</Title>
                        <Form
                            name="sign-up-form"
                            layout="vertical"
                            labelCol={{span: 24}}
                            wrapperCol={{span: 24}}
                            initialValues={{
                                username: 'admin',
                                password: 'admin846195789543.',
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            requiredMark={false}
                        >
                            <Row gutter={[8, 0]}>
                                <Col xs={24}>
                                    <Form.Item<FieldType> label={t("Username")} name="username" rules={[ {required: true, message: t('Enter username')},]}>
                                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t("Username")}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <Form.Item<FieldType> label={t("Password")} name="password" rules={[ {required: true, message: t('Enter password')}, ]}>
                                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder={t("Password")}/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <Form.Item<FieldType> name="remember" valuePropName="checked">
                                        <Checkbox>{t("Remember me")}</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Flex align="center" justify="space-between">
                                    <Button type="primary" htmlType="submit" size="middle" loading={loading}> {t('Login')} </Button>
                                </Flex> </Form.Item>
                        </Form>
                    </Flex>
                </Col>
            </Row>
            <TFAModal ref={TFARef} onSuccessToken={TFAOnSuccess} />
        </>
    );
};

export default Login;
