import React, {useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet-async";
import {ITEMS_UTILITY_CLOSING,} from "../../../routes/ConfigRoutes";
import {useStylesContext} from "../../../context/StylesContext";
import {
    Button,
    Col,
    DatePicker,
    Flex,
    Form,
    message,
    Row,

    Tooltip
} from "antd";
import {useParams} from "react-router-dom";
import {Card} from "../../../components/Card/Card";
import {PageHeader} from "../../../components/PageHeader/PageHeader";
import {useTranslation} from "react-i18next";
import {StatusCode} from "../../../service/AppService";
import {AppName} from "../../../config/Config";
import {ToNumber} from "../../../helper/Helper";
import {ReloadOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {FlexCustom} from "../../../components/Flex/FlexCustom";
import Summary, {ChildComponentRef} from "./ClosingCurrentSummary";
import {GetDetailClosing, SaveClosing} from "../../../service/utility/ClosingServices";
import {IClosingFormType} from "../../../types/utility/ClosingType";
import CheckAcess from "../../../routes/Helper/CheckAcess";

const ClosingEditor = () => {
    const {t} = useTranslation();
    const {closingIdParam} = useParams<{ closingIdParam: string }>();
    const stylesContext = useStylesContext();
    const [closingId, setClosingId] = useState(ToNumber(closingIdParam));
    const [closingDate, setClosingDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm<IClosingFormType>();
    const childRef = useRef<ChildComponentRef>(null);
    const title = (
        <FlexCustom justifyContent={"space-between"}>
            <div>{closingId === 0 ? t('NewFor', {field: 'Closing Daily'}) : closingDate}</div>
            <FlexCustom justifyContent="end">
                <Tooltip title={t("Recalculation")}>
                    <Button type="primary" shape="circle" icon={
                        <ReloadOutlined/>} onClick={() =>
                        {
                            if (childRef.current) {
                                // Execute the function in the child component from the parent
                                childRef.current.ReloadList();
                            }
                        }
                    }/>
                </Tooltip>
            </FlexCustom>
        </FlexCustom>
    )
    const onFinish = async (values: IClosingFormType) => {
        try {
            setLoading(true);
            if(closingId === 0) if(!CheckAcess('ClosingDaily.New')) return;
            const dateValue = dayjs(values.closingDate);
            const formattedValues = {
                ...values,
                closingDate: dateValue.format('YYYY-MM-DD'),
            };
            const result = await SaveClosing(closingId, formattedValues);
            if (result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                // const data = result.data as IClosingResponseData;
                 setClosingId(result.data);
                message.open({type: 'success', content: result.message});
            }

        } catch (error: any) {
            console.log('catch', error);
        } finally {
            setLoading(false);
        }
    };
    const SetFormReload = (data: IClosingFormType) => {
        if (Object.values(data).length > 0) {
            form.setFieldsValue({
                closingId: data.closingId,
                closingDate: dayjs(data.closingDate),
            });
            dateCloseChange(dayjs(data.closingDate))
            setClosingDate(data.closingDate as string);
        }
        else{
            form.setFieldsValue({
                closingId: 0,
                closingDate: dayjs(),
            });
            dateCloseChange(dayjs())
        }
    }
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const ReloadData = async () => {
        try {
            setLoading(true);
            const result = await GetDetailClosing(closingId);

            if (result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data;
                SetFormReload(data);
            }

        } catch (error: any) {
            console.log('catch', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        ReloadData();
    }, []);



    const dateCloseChange = (date:dayjs.Dayjs) =>{
        if (childRef.current) {
            // Execute the function in the child component from the parent
            childRef.current.closeDateChanged(date);
        }
    }

    return (
        <div>
            <Helmet>
                <title>Closing | {AppName}</title>
            </Helmet>
            <Flex vertical gap="middle">
                <PageHeader title="Closing" breadcrumbs={ITEMS_UTILITY_CLOSING} />
                <Row {...stylesContext?.rowProps}>
                    <Col xs={24} lg={24}>
                        <Card title={title}>
                            <Form
                                form={form}
                                name="bank"
                                layout="vertical"
                                // initialValues={{closingDate: dayjs('2024-08-26')}}
                                labelCol={{span: 24}}
                                wrapperCol={{span: 24}}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                requiredMark={true}
                                size={"small"}
                            >

                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}>
                                        <Form.Item label="Close Date" name="closingDate"
                                            rules={[
                                                {required: true, message: t('Cannot be empty')},
                                            ]}

                                        >
                                            <DatePicker
                                                onChange={dateCloseChange}
                                                showTime={false}
                                                format="YYYY-MM-DD"
                                                style={{width: '100%'}}
                                                disabled={closingId>0}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]}>
                                    <Col xs={24}>
                                        <Summary ref={childRef}/>
                                    </Col>
                                </Row>
                                <Form.Item hidden={closingId>0}>
                                <Flex align="center" justify="space-between">
                                    <Button type="primary" htmlType="submit" size="middle" loading={loading} style={{marginTop: '5px'}}> {t('Close Now')} </Button>
                                </Flex>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Flex>
        </div>
    );
};

export default ClosingEditor;
