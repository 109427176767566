import {AxiosRequestConfig} from "axios";
import {IBankFormType} from "../../types/master/BankType";
import {IReturnResponse} from "../../types/AppServiceType";
import {AllErrorType, client, ResponseChecker, ReturnCatchResponse} from "../AppService";


export const GetListBank = async () : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    return await client.get<IReturnResponse>("/api/master/bank",config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const GetDetailBank = async (bankId:string = '') : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const url = bankId === '' ? `/api/master/bank/new/` : `/api/master/bank/${bankId}`;
    return await client.get<IReturnResponse>(url,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const DeleteBank = async (bankId:string) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',

        },
    };
    return await client.delete<IReturnResponse>(`/api/master/bank/${bankId}`,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const SaveBank = async (oldBank:string, formData:IBankFormType) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const url = oldBank === '' ? `/api/master/bank/new` : `/api/master/bank/${oldBank}`;
    return await client.post<IReturnResponse>( url,formData, config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
