import {Button, Col, Input, message, Modal, Row} from "antd";
import React, {forwardRef, useImperativeHandle, useState} from "react";
import {FlexCustom} from "../../components/Flex/FlexCustom";
import {SubmitLogin, SubmitTFAToken} from "../../service/LoginServices";
import {StatusCode} from "../../service/AppService";
import {ILoginTokenFormType} from "../../types/LoginType";
import {useLoginContext} from "../../context/LoginProvider";
import {useTranslation} from "react-i18next";


interface Props {
    onSuccessToken: (msg:string) => void
}
export interface TFAPropsHandles {
    setOpenModal: (value:boolean) => void,
}


const TFAModal = forwardRef<TFAPropsHandles, Props>((Props, ref) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);
    const { loggedIn,setLoggedIn } = useLoginContext();

    useImperativeHandle(ref, () => ({
        setOpenModal: () => {
            console.log('opened');
            setOpen(true);
        }
    }));

    const SubmitTFA = async () => {
        try {
            setLoading(true);
            const data = {username:loggedIn.username, token: token } as ILoginTokenFormType;
            const result = await SubmitTFAToken(data);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                setLoggedIn({username: result.data.username, isLoggedIn: true, isTFAActive: result.data.isTFAActive, isTFASuccess:true})
                Props.onSuccessToken(result.message);
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    }


    return(
        <Modal
            title={<p>2FA Authentication</p>}
            centered
            footer={null}
            open={open}
            width={250}
            loading={loading}
        >
            <Row gutter={[8, 8]}>
                <Col>
                    <Input.OTP
                        formatter={(str) => str.toUpperCase()}
                        value={token}
                        onChange={(value) => setToken(value)}
                    />
                </Col>
                <Col span={12}>
                    <FlexCustom>
                        <Button type={"primary"} danger onClick={SubmitTFA}>{t('Submit')}</Button>
                        <Button danger onClick={()=>{setOpen(false)}}>{t('Cancel')}</Button>
                    </FlexCustom>
                </Col>
            </Row>
        </Modal>
    )
});
export default TFAModal;
