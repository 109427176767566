import React, {useEffect,  useState} from "react";
import {Helmet} from "react-helmet-async";
import {ITEMS_MASTER_BANK} from "../../../routes/ConfigRoutes";
import {useStylesContext} from "../../../context/StylesContext";
import {Button, Col, Flex, Form, Input, InputNumber, message, Row, Select, Switch} from "antd";
import { useParams} from "react-router-dom";
import {Card} from "../../../components/Card/Card";
import {PageHeader} from "../../../components/PageHeader/PageHeader";
import {useTranslation} from "react-i18next";
import {StatusCode} from "../../../service/AppService";
import {AppName} from "../../../config/Config";
import {IBankFormType} from "../../../types/master/BankType";
import {GetDetailBank, SaveBank} from "../../../service/master/BankServices";
import CheckAcess from "../../../routes/Helper/CheckAcess";

const BankEditor = () => {
    const { t } = useTranslation();
    const { bankParam } = useParams<{ bankParam: string }>();
    const stylesContext = useStylesContext();
    const [bank, setUser] = useState(bankParam??'');
    const [loading, setLoading] = useState(false);
    const [bankTypeList, setBankTypeList] = useState([] as string[])
    const [useRate, setUseRate] = useState(true);
    const [form] = Form.useForm<IBankFormType>()
    const { Option } = Select;
    const title = () => {
        return bank === '' ? t('NewFor', {field:'Bank Provider'}) : t(`ViewFor`, {field: 'Bank Provider',data: bank});
    }
    const onFinish = async (values: IBankFormType) => {
        try {
            setLoading(true);
            if(bank != '') if(!CheckAcess('Bank.Edit')) return;
            else if(bank == '') if(!CheckAcess('Bank.New')) return;
            const result = await SaveBank(bank ?? '', values);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                setUser(values.bank);
                message.open({type: 'success', content: result.message});
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const ReloadData = async () => {
        try {
            setLoading(true);
            const result = await GetDetailBank(bank);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data;
                if(data.data.length !== 0) {
                    form.setFieldsValue({
                        bank: data.data.bank,
                        bankType: data.data.bankType,
                        defaultCoinRate: data.data.defaultCoinRate,
                        description: data.data.description,
                        seq: data.data.seq,
                    });
                }
                setBankTypeList(data.bankTypeList);
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };

    const BankTypeChanged = (value:string)=> {
        if(value === 'PULSA') {
            setUseRate(true);
            form.setFieldValue('defaultCoinRate', 80)
        }
        else {
            setUseRate(false);
            form.setFieldValue('defaultCoinRate', 100)
        }
    }

    useEffect(() => {
        ReloadData();
    }, []);



  return (
    <div>
      <Helmet>
        <title>Bank | {AppName}</title>
      </Helmet>
        <Flex vertical gap="middle">
            <PageHeader title="Role" breadcrumbs={ITEMS_MASTER_BANK}/>
            <Row {...stylesContext?.rowProps}>
                <Col xs={24} lg={24}>
                    <Card title={title()}>
                    <Form
                        form={form}
                        name="bank"
                        layout="vertical"
                        initialValues={{seq:0, defaultCoinRate:100}}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        requiredMark={false}
                    >
                        <Row gutter={[8, 0]}>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Bank" name="bank"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 2, message: t('Minimum character length is', {length: '2'})},
                                        {max: 50, message: t('Maximum character length is', {length: '50'})}
                                    ]}
                                >
                                    <Input placeholder="Bank"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item name="bankType" label="Bank Type" rules={[{ required: true }]}>
                                    <Select
                                        placeholder="Bank Type"
                                        // onChange={onGenderChange}
                                        allowClear
                                        onChange={BankTypeChanged}
                                    >
                                        {
                                            bankTypeList.map((x,idx)=> (
                                                <Option value={x} key={idx}>{x}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Default Coin Rate" name="defaultCoinRate"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                    ]}
                                >
                                    <InputNumber style={{ width: '100%' }}
                                        formatter={(value) => `${value}%`}
                                        parser={(value) => value?.replace('%', '') as unknown as number}
                                        placeholder="Enter Default Coin Rate"
                                        disabled={!useRate}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Description" name="description"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {max: 50, message: t('Maximum character length is', {length: '50'})}
                                    ]}
                                >
                                    <Input placeholder="Description"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Sequence" name="seq"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                    ]}
                                >
                                    <InputNumber min={0} max={999} style={{ width: '100%' }} placeholder="Sequence"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Flex align="center" justify="space-between">
                                <Button type="primary" htmlType="submit" size="middle" loading={loading}> {t('Save')} </Button>
                            </Flex> </Form.Item>
                    </Form>
                    </Card>
                </Col>
            </Row>
        </Flex>
    </div>
  );
};

export default BankEditor;
