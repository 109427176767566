import React from "react";
import {MenuProps} from "antd";
import {HomeOutlined, MenuUnfoldOutlined, SettingOutlined} from "@ant-design/icons";
import {PATH_HOME, PATH_MASTER, PATH_SETTING, PATH_TRANSACTION, PATH_UTILITY} from "./RoutePathList";
import AccessLink from "./Helper/AccessLink";

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
): MenuItem => {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
};

export const NavItems: MenuProps['items'] = [
    getItem(<AccessLink to={PATH_HOME.home}>Home</AccessLink>, 'default', <HomeOutlined/>),
    getItem('Transaction', 'transaction', <SettingOutlined />, [
        getItem(<AccessLink to={PATH_TRANSACTION.dpwd} accessCode={'DPWD.View'}>Deposit and Withdraw</AccessLink>, 'dpwd', null),
        getItem(<AccessLink to={PATH_TRANSACTION.cashflow} accessCode={'TransBank.View'}>Bank</AccessLink>, 'cashflow', null),
        getItem(<AccessLink to={PATH_TRANSACTION.coinflow} accessCode={'Coin.View'}>Coin</AccessLink>, 'coinflow', null),
    ]),
    getItem('Utility', 'utility', <SettingOutlined />, [
        getItem(<AccessLink to={PATH_UTILITY.closing} accessCode={'ClosingDaily.View'}>Closing Daily</AccessLink>, 'closing daily', null),
        getItem(<AccessLink to={PATH_UTILITY.today} accessCode={'ClosingDaily.View'}>Today Summary</AccessLink>, 'today summary', null),
    ]),
    getItem('Master', 'master', <MenuUnfoldOutlined />, [
        getItem(<AccessLink to={PATH_MASTER.bank} accessCode={'Bank.View'}>Bank</AccessLink>, 'bank', null),
        getItem(<AccessLink to={PATH_MASTER.bank_account} accessCode={'BankAccount.View'}>Bank Account</AccessLink>, 'bankAccount_dpwd', null),
        // getItem(<Link to={PATH_MASTER.transType}>Trans Type</Link>, 'transType', null),
        getItem(<AccessLink to={PATH_MASTER.member} accessCode={'Member.View'}>Member</AccessLink>, 'member', null),
    ]),
    getItem('Setting', 'setting', <SettingOutlined />, [
        getItem(<AccessLink to={PATH_SETTING.user} accessCode={'User.View'}>User</AccessLink>, 'user', null),
        getItem(<AccessLink to={PATH_SETTING.role} accessCode={'Role.View'}>Role</AccessLink>, 'role', null),
    ]),
];
