import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    Col,
    ConfigProvider, Row,
    Table,
    TableColumnsType,
} from "antd";

import {StatusCode} from "../../../service/AppService";
import {GetCurrentSummary, GetListClosing} from "../../../service/utility/ClosingServices";
import {useTranslation} from "react-i18next";
import './ClosingCurrentSummary.css';
import {useStylesContext} from "../../../context/StylesContext";
import ClosingCurrentSummaryTransType from "./ClosingCurrentSummaryTransType";
import {
    IColumnSummaryOperatorBankAccountTransTypeByOperator,
    ISummaryBankAccount, IDataSummaryOperatorBankAccountTransTypeByOperator,
    ISummaryTransType
} from "../../../types/utility/ClosingType";
import ClosingCurrentSummaryBankAccount from "./ClosingCurrentSummaryBankAccount";
import ClosingCurrentSummaryBanAccountByOperator from "./ClosingCurrentSummaryBanAccountByOperator";
import ClosingCurrentSummaryTransTypeByOperator from "./ClosingCurrentSummaryTransTypeByOperator";
import dayjs from "dayjs"; // Impor gaya kustom

export type ChildComponentRef = {
    ReloadList: () => void;
    closeDateChanged: (dateClose: dayjs.Dayjs) => void;
};

type ChildComponentProps = {
    // Define any props if needed

};


const Summary = forwardRef<ChildComponentRef, ChildComponentProps>((props, ref) => {
    const { t } = useTranslation();
    const stylesContext = useStylesContext();
    const [tableLoading, setTableLoading] = useState(false);
    const [dateClose, setDateClose] = useState<dayjs.Dayjs|null>(null);
    const [dataSourceTransType, setDataSourceTransType] = useState<ISummaryTransType[]>([]);
    const [dataSourceBankAccount, setDataSourceBankAccount] = useState<ISummaryBankAccount[]>([]);
    const [dataSourceBankAccountByOperator, setDataSourceBankAccountByOperator] = useState<IDataSummaryOperatorBankAccountTransTypeByOperator[]>([]);
    const [dataColumnBankAccountByOperator , setDataColumnBankAccountByOperator] = useState<IColumnSummaryOperatorBankAccountTransTypeByOperator[]>([]);
    const [dataSourceTransTypeByOperator, setDataSourceTransTypeByOperator] = useState<IDataSummaryOperatorBankAccountTransTypeByOperator[]>([]);
    const [dataColumnTransTypeByOperator , setDataColumnTransTypeByOperator] = useState<IColumnSummaryOperatorBankAccountTransTypeByOperator[]>([]);

    const ReloadList = async () => {
        try {
            setTableLoading(true);
            const date:string = dateClose ? dateClose.format('YYYY-MM-DD') : '';
            const result = await GetCurrentSummary(date);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data;
                setDataSourceTransType(data['SummaryTransType']);
                setDataSourceBankAccount(data['SummaryBankAccount']);
                setDataSourceBankAccountByOperator(data['SummaryBankAccountByOperator']['data']);
                setDataColumnBankAccountByOperator(data['SummaryBankAccountByOperator']['column']);
                setDataSourceTransTypeByOperator(data['SummaryTransTypeByOperator']['data']);
                setDataColumnTransTypeByOperator(data['SummaryTransTypeByOperator']['column']);
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setTableLoading(false);
        }
    };
    const closeDateChanged = (dateClose: dayjs.Dayjs) => {
        setDateClose(dateClose);
    }
    useEffect(() => {
        if (dateClose !== null) {
            ReloadList();
        }
    }, [dateClose]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (dateClose !== null) {
                ReloadList();
            }
        }, 10000); // 10000 ms = 10 detik

        // Membersihkan interval ketika komponen dihapus
        return () => clearInterval(intervalId);
    }, []);

    useImperativeHandle(ref, () => ({
        ReloadList,
        closeDateChanged
    }));

    return (
        <Row {...stylesContext?.rowProps}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
                <ClosingCurrentSummaryTransType dataSourceIn={dataSourceTransType} />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={16} xxl={18}>
                <ClosingCurrentSummaryBankAccount dataSourceIn={dataSourceBankAccount} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <ClosingCurrentSummaryBanAccountByOperator dataSourceIn={dataSourceBankAccountByOperator} dataColumnIn={dataColumnBankAccountByOperator} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <ClosingCurrentSummaryTransTypeByOperator dataSourceIn={dataSourceTransTypeByOperator} dataColumnIn={dataColumnTransTypeByOperator} />
            </Col>

        </Row>
  );
});

export default Summary;
