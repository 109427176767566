import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import { ITEMS_TRANSACTION_CAHSFLOW,} from "../../../routes/ConfigRoutes";
import {useStylesContext} from "../../../context/StylesContext";
import {
    Button,
    Col,
    DatePicker,
    Flex,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Select,
    Space,
    Tooltip
} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {Card} from "../../../components/Card/Card";
import {PageHeader} from "../../../components/PageHeader/PageHeader";
import {useTranslation} from "react-i18next";
import {StatusCode} from "../../../service/AppService";
import {AppName} from "../../../config/Config";
import {ToNumber} from "../../../helper/Helper";
import {IBankAccountListType} from "../../../types/master/BankAccountType";
import {GetDetailCashFlow, SaveCashFlow} from "../../../service/transaction/CashFlowServices";
import {isEmpty} from "lodash";
import {PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {GetLookupBankAccountList} from "../../../service/master/BankAccountServices";
import dayjs from "dayjs";
import {ICashFormType, ICashFlowResponseData} from "../../../types/transaction/CashFlowType";
import {FlexCustom} from "../../../components/Flex/FlexCustom";
import CheckAcess from "../../../routes/Helper/CheckAcess";

const CashFlowEditor = () => {
    const { t } = useTranslation();
    const { cashflowIdParam } = useParams<{ cashflowIdParam: string }>();
    const navigate = useNavigate();
    const stylesContext = useStylesContext();
    const [cashflowId, setCashflowId] = useState(ToNumber(cashflowIdParam));
    const [cashflowNumber, setCashflowNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [bankAccountList, setBankAccountList] = useState([] as IBankAccountListType[])
    const [cashflowTypeList, setTransTypeList] = useState([] as string[])
    const [form] = Form.useForm<ICashFormType>();
    const [loadingBankAccountList, setLoadingBankAccountList] = useState<boolean>(false);
    const [useRate, setUseRate] = useState(true);

    const { Option } = Select;
    const title = (
        <FlexCustom justifyContent={"space-between"}>
            <div>{cashflowId === 0 ? t('NewFor', {field:'BANK'}) : cashflowNumber}</div>
            <FlexCustom justifyContent="end">
                <Tooltip title={t("New Record")}>
                    <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={()=>navigate('/transaction/cashflow/new')} />
                </Tooltip>
            </FlexCustom>
        </FlexCustom>
    )
    const onFinish = async (values: ICashFormType) => {
        try {
            setLoading(true);
            if(cashflowId !== 0) if(!CheckAcess('TransBank.Edit')) return;
            else if(cashflowId === 0) if(!CheckAcess('TransBank.New')) return;
            const dateValue = dayjs(values.cashflowDate);
            const formattedValues = {
                ...values,
                 cashflowDate: dateValue.format('YYYY-MM-DD HH:mm:ss'),
            };
            const result = await SaveCashFlow(cashflowId, formattedValues);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data as ICashFlowResponseData;
                SetFormReload(data);

                message.open({type: 'success', content: result.message});
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };
    const SetFormReload = (data :ICashFlowResponseData) => {
        if(Object.values(data.data).length > 0) {

            form.setFieldsValue({
                cashflowId: data.data.cashflowId,
                cashflowNumber: data.data.cashflowNumber,
                cashflowDate: isEmpty(data.data.cashflowDate) ? '' :dayjs(data.data.cashflowDate),
                cashflowType: data.data.cashflowType,
                cashflowBankAccountId: data.data.cashflowBankAccountId,
                amount: data.data.amount,
                rate: data.data.rate,
                refNumber: data.data.refNumber,
                description: data.data.description,
            });

            setCashflowId(ToNumber(data.data.cashflowId));
            setCashflowNumber(data.data.cashflowNumber);
        }
        setTransTypeList(data.cashflowTypeList)
        setBankAccountList(data.bankAccountList);
    }
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const ReloadData = async () => {
        try {
            setLoading(true);
            const result = await GetDetailCashFlow(cashflowId);

            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data as ICashFlowResponseData;
                console.log(data);
                SetFormReload(data);
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };
    const ReloadBankAccount = async () => {
        try {
            setLoadingBankAccountList(true);
            const result = await GetLookupBankAccountList('CashFlow');

            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data;
                setBankAccountList(data);
                const filteredBanks = bankAccountList.filter(bank => bank.useByMe);
                if(filteredBanks.length > 0) form.setFieldValue('cashflowBankAccountId', filteredBanks[0].bankAccountId);
                console.log(filteredBanks)
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoadingBankAccountList(false);
        }
    };

    const handleChangeTransBankAccount = async (cashflowBankAccountId: number) => {
        form.setFieldValue('cashflowBankAccountId', cashflowBankAccountId);
        const bankAccountSelected = bankAccountList.filter(bankAccount => bankAccount.bankAccountId === cashflowBankAccountId);
        if(bankAccountSelected.length > 0) {
            form.setFieldValue('rate', bankAccountSelected[0].defaultCoinRate);
            if(bankAccountSelected[0].bankType === 'PULSA') setUseRate(true);
            else setUseRate(false);
        }
    };


    useEffect(() => {
        ReloadData();
    }, []);



  return (
    <div>
      <Helmet>
        <title>BANK | {AppName}</title>
      </Helmet>
        <Flex vertical gap="middle">
            <PageHeader title="BANK" breadcrumbs={ ITEMS_TRANSACTION_CAHSFLOW } />
            <Row {...stylesContext?.rowProps}>
                <Col xs={24} lg={24}>
                    <Card title={title}>
                        <Form
                            form={form}
                            name="bank"
                            layout="vertical"
                            initialValues={{isActive:true, cashflowDate: dayjs(), amount:0, rate:100}}
                            labelCol={{span: 24}}
                            wrapperCol={{span: 24}}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            requiredMark={true}
                            size={"small"}
                        >

                            <Row gutter={[8, 0]}>
                                <Col xs={24} lg={24}>
                                    <Form.Item label="Transaction Number" name="cashflowNumber" hidden>
                                        <Input disabled={true} placeholder="Automatic Number"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[8, 0]}>
                                <Col md={24} lg={24}>
                                    <Card title={t('Transaction')}>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                                                <Form.Item label="Transaction Date" name="cashflowDate"
                                                    rules={[
                                                        {required: true, message: t('Cannot be empty')},
                                                    ]}
                                                >
                                                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{width:'100%'}}/>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                                                <Form.Item name="cashflowType" label="Transaction Type" rules={[{ required: true }]}>
                                                    <Select placeholder="Select a Type" showSearch allowClear >
                                                        {
                                                            cashflowTypeList.map((x,idx)=> (
                                                                <Option value={x} key={idx}>{x}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                                                <Form.Item name="cashflowBankAccountId" label="Bank Account" rules={[{ required: true }]}>
                                                    <Space.Compact style={{ width: '100%' }}>
                                                        <Select placeholder={t('Select a Bank Account')}
                                                            style={{ width: 'calc(100% - 30px)'}}
                                                            showSearch
                                                            allowClear
                                                            loading={loadingBankAccountList}
                                                            value={form.getFieldValue('cashflowBankAccountId')}
                                                            onChange={handleChangeTransBankAccount}>
                                                            {/*onChange={(val) => form.setFieldValue('cashflowBankAccountId', val)}>*/}
                                                            {
                                                                bankAccountList.map((x,idx)=> (
                                                                    <Option value={x.bankAccountId} key={idx} disabled={x.inUse}>{x.bankAccountCode} {x.inUse?'(' + t('locked by', {username: x.useBy}) + ')':''}</Option>
                                                                ))
                                                            }
                                                        </Select>
                                                        <Button onClick={ReloadBankAccount}><ReloadOutlined /></Button>
                                                    </Space.Compact>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                                                <Form.Item label="Amount" style={{margin:'0'}}>
                                                    <Space.Compact style={{ width: '100%' }}>
                                                        <Form.Item name="amount"
                                                            style={{ width: 'calc(100% - 67px)' }}
                                                            rules={[
                                                                {required: true, message: t('Cannot be empty')},
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                style={{ width: '100%' }}
                                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                                                                placeholder="Enter Amount"
                                                            />
                                                        </Form.Item>
                                                        <Form.Item name="rate"
                                                            style={{ width: '67px' }}
                                                            rules={[
                                                                {required: true, message: t('Cannot be empty')},
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                style={{ width: '100%' }}
                                                                formatter={(value) => `${value}%`}
                                                                parser={(value) => value?.replace('%', '') as unknown as number}
                                                                placeholder="Rate"
                                                                disabled={!useRate}
                                                            />
                                                        </Form.Item>
                                                    </Space.Compact>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xs={24}>
                                    <Card title={t('Others Informations')}>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
                                                <Form.Item label="Reference Number" name="refNumber">
                                                    <Input placeholder="Enter Reference Number if exists"/>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={24} lg={24} xl={12} xxl={12}>
                                                <Form.Item label="Descriptions / Informations" name="description">
                                                    <Input placeholder="Enter Reference Number if exists"/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Flex align="center" justify="space-between">
                                    <Button type="primary" htmlType="submit" size="middle" loading={loading} style={{marginTop:'5px'}}> {t('Save')} </Button>
                                </Flex> </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Flex>
    </div>
  );
};

export default CashFlowEditor;
