import { IReturnResponse} from "../types/AppServiceType";
import {AxiosRequestConfig} from "axios";
import {AllErrorType, client, ResponseChecker, ReturnCatchResponse} from "./AppService";
import {IRoleFormType} from "../types/RoleType";

export const GetListRole = async () : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    return await client.get<IReturnResponse>("/api/setting/role",config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const GetDetailRole = async (role:string = '') : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const url = role === '' ? `/api/setting/role/new/` : `/api/setting/role/${role}`;
    return await client.get<IReturnResponse>(url,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const DeleteRole = async (role:string) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',

        },
    };
    return await client.delete<IReturnResponse>(`/api/setting/role/${role}`,config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
export const SaveRole = async (oldRole:string, formData:IRoleFormType) : Promise<IReturnResponse>  => {
    const config: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
    const url = oldRole === '' ? `/api/setting/role/new` : `/api/setting/role/${oldRole}`;
    return await client.post<IReturnResponse>( url,formData, config)
        .then((response) => {
            let result = response.data;
            if (!result.success) {
                if(AllErrorType.includes(result.status))
                {
                    ResponseChecker(result);
                }
                return result;
            }
            return response.data;
        })
        .catch(err => {
            return ReturnCatchResponse(err.message);
        });
}
