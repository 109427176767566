import React, {useEffect,  useState} from "react";
import {Helmet} from "react-helmet-async";
import {ITEMS_MASTER_BANK_ACCOUNT, ITEMS_MASTER_TRANSTYPE, ITEMS_SETTING_ROLE} from "../../../routes/ConfigRoutes";
import {useStylesContext} from "../../../context/StylesContext";
import {Button, Checkbox, Col, Flex, Form, Input, InputNumber, message, Row, Select, Switch} from "antd";
import {Link, useParams} from "react-router-dom";
import {Card} from "../../../components/Card/Card";
import {PageHeader} from "../../../components/PageHeader/PageHeader";
import {useTranslation} from "react-i18next";
import {StatusCode} from "../../../service/AppService";
import {AppName} from "../../../config/Config";
import {ToNumber} from "../../../helper/Helper";
import {ITransTypeFormType} from "../../../types/master/TransTypeType";
import {GetDetailTransType, SaveTransType} from "../../../service/master/TransTypeServices";
import {GetDetailBankAccount} from "../../../service/master/BankAccountServices";

const TransTypeEditor = () => {
    const { t } = useTranslation();
    const { transTypeIdParam } = useParams<{ transTypeIdParam: string }>();
    const stylesContext = useStylesContext();
    const [transTypeId, setTransTypeId] = useState(ToNumber(transTypeIdParam));
    const [transType, setTransType] = useState('');
    const [crdbList, setCrdbList] = useState([] as string[])
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm<ITransTypeFormType>()
    const { Option } = Select;
    const title = () => {
        return transTypeId === 0 ? t('NewFor', {field:'Trans Type'}) : t(`ViewFor`, {field: 'Trans Type',data: transType});
    }
    const onFinish = async (values: ITransTypeFormType) => {
        try {
            setLoading(true);
            console.log(transTypeId)
            const result = await SaveTransType(transTypeId, values);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                setTransTypeId(ToNumber(result.data));
                setTransType(values.transType);
                message.open({type: 'success', content: result.message});
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const ReloadData = async () => {
        try {
            setLoading(true);
            console.log(transTypeId)
            const result = await GetDetailTransType(transTypeId);

            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                const data = result.data;
                if(data.data.length !== 0) {
                    form.setFieldsValue({
                        transType: data.data.transType,
                        moduleType: data.data.moduleType,
                        indonesiaName: data.data.indonesiaName,
                        khmerName: data.data.khmerName,
                        vietnamName: data.data.vietnamName,
                        crdb: data.data.crdb,
                        description: data.data.description,
                        seq: data.data.seq,
                        isActive: data.data.isActive,
                    });
                }
                setCrdbList(data.crdbList);
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        ReloadData();
    }, []);



  return (
    <div>
      <Helmet>
        <title>Trans Type |  {AppName}</title>
      </Helmet>
        <Flex vertical gap="middle">
            <PageHeader title="Trans Type" breadcrumbs={ITEMS_MASTER_TRANSTYPE}/>
            <Row {...stylesContext?.rowProps}>
                <Col xs={24} lg={24}>
                    <Card title={title()}>
                    <Form
                        form={form}
                        name="bank"
                        layout="vertical"
                        initialValues={{moduleType: 'DPWD', seq:0, isActive:true}}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        requiredMark={false}
                    >
                        <Row gutter={[8, 0]}>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Transaction Type" name="transType"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 2, message: t('Minimum character length is', {length: '2'})},
                                        {max: 50, message: t('Maximum character length is', {length: '100'})}
                                    ]}
                                >
                                    <Input placeholder="Transaction Type"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Indonesia" name="indonesiaName"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 2, message: t('Minimum character length is', {length: '2'})},
                                        {max: 50, message: t('Maximum character length is', {length: '100'})}
                                    ]}
                                >
                                    <Input placeholder="Indonesia"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Khmer" name="khmerName"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 2, message: t('Minimum character length is', {length: '2'})},
                                        {max: 50, message: t('Maximum character length is', {length: '100'})}
                                    ]}
                                >
                                    <Input placeholder="Khmer"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Vietnam" name="vietnamName"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 2, message: t('Minimum character length is', {length: '2'})},
                                        {max: 50, message: t('Maximum character length is', {length: '100'})}
                                    ]}
                                >
                                    <Input placeholder="Vietnam"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item name="crdb" label="Credit or Debit" rules={[{ required: true }]}>
                                    <Select
                                        placeholder="Credit or Debit"
                                        // onChange={onGenderChange}
                                        allowClear
                                    >
                                        {
                                            crdbList.map((x,idx)=> (
                                                <Option value={x} key={idx}>{x}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Description" name="description"
                                    rules={[
                                        {max: 255, message: t('Maximum character length is', {length: '255'})}
                                    ]}
                                >
                                    <Input placeholder="Description"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <Form.Item label="Sequence" name="seq"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                    ]}
                                >
                                    <InputNumber min={0} max={999} style={{ width: '100%' }} placeholder="Sequence"/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={4}>
                                <Form.Item label="Module Type" name="moduleType"
                                    rules={[
                                        {required: true, message: t('Cannot be empty')},
                                        {min: 2, message: t('Minimum character length is', {length: '2'})},
                                        {max: 50, message: t('Maximum character length is', {length: '50'})}
                                    ]}
                                >
                                    <Input placeholder="Module Type" disabled={true}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={4}>
                                <Form.Item name="isActive" label="Active" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Flex align="center" justify="space-between">
                                <Button type="primary" htmlType="submit" size="middle" loading={loading}> {t('Save')} </Button>
                            </Flex> </Form.Item>
                    </Form>
                    </Card>
                </Col>
            </Row>
        </Flex>
    </div>
  );
};

export default TransTypeEditor;
