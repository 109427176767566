import React, {useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet-async";
import {ITEMS_MASTER_BANK_ACCOUNT} from "../../../routes/ConfigRoutes";
import {useStylesContext} from "../../../context/StylesContext";
import {
    Button,
    Checkbox,
    Col,
    Flex,
    Input,
    InputRef,
    message,
    Modal,
    Row,
    Space,
    Table,
    TableColumnsType,
    Tooltip
} from "antd";
import {useNavigate} from "react-router-dom";
import {Card} from "../../../components/Card/Card";
import {PageHeader} from "../../../components/PageHeader/PageHeader";
import {
    DeleteOutlined,
    EditOutlined, FilterOutlined,
    LockOutlined,
    PlusOutlined,
    ProjectOutlined,
    SearchOutlined, SortAscendingOutlined, UnlockOutlined
} from "@ant-design/icons";
import {FlexCustom} from "../../../components/Flex/FlexCustom";
import {GetStyle, PostStyle} from "../../../service/UserStyleServices";
import {ColumnType} from "antd/es/table";
import {FilterConfirmProps, TableRowSelection} from "antd/es/table/interface";
import {useTranslation} from "react-i18next";
import {AppName} from "../../../config/Config";
import {StatusCode} from "../../../service/AppService";
import {DeleteBankAccount, GetListBankAccount, SetUseBankAccount} from "../../../service/master/BankAccountServices";
import {BankAccountProps} from "../../../types/master/BankAccountType";
import './BankAccount.css';
import CheckAcess from "../../../routes/Helper/CheckAcess"; // Impor gaya kustom

interface DataType {
    bankAccountId:number
    bankAccountCode:string
    moduleType:string
    bankAccountNumber:string
    bankAccountName:string
    bank:string
    phoneNumber:string
    phoneExpiredDate:string
    phoneRemainPulsa:number
    userInternetBanking:string
    pwdInternetBanking:string
    userMobileBanking:string
    pwdMobileBanking:string
    pinTransactionBanking:string
    description:string
    isLimit:boolean
    isPremium:boolean
    isBlocked:boolean
    isActive:boolean
    seq:number
    inUse:boolean
    useBy:string
    useDate:string
    createdBy:string
    createdDate:string
    modifiedBy:string
    modifiedDate:string
}

const styleName = "BankAccountColumns";
const excludedKeys = ['createdBy', 'createdDate', 'modifiedBy', 'modifiedDate'];


const BankAccount: React.FC<BankAccountProps> = ({moduleType}) => {
    const { t } = useTranslation();
    const stylesContext = useStylesContext();
    const navigate = useNavigate();
    const [openModalColumn, setOpenModalColumn] = useState(false);

    const [searchedColumn, setSearchedColumn] = useState<DataType>();
    const searchInput = useRef<InputRef>(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState<DataType[]>([]);
    const [sortingEnabled, setSortingEnabled] = useState(false);
    const [searchingEnabled, setSearchingEnabled] = useState(false);
    type DataIndex = keyof DataType;

    const handleSearchs = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchedColumn(dataIndex as any);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
    };
    const getColumnSearchProps = (dataIndex: keyof DataType): ColumnType<DataType> => ({
        filterDropdown: searchingEnabled ? ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={t('SearchField', {field: String(dataIndex)})}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearchs(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearchs(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                        {t('Search')}
                    </Button>
                    <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('Reset')}
                    </Button>
                    <Button type="link" size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchedColumn(dataIndex as any);
                        }} >
                        Filter
                    </Button>
                    <Button type="link" size="small"
                        onClick={() => {
                            close();
                        }}>
                        close
                    </Button>
                </Space>
            </div>
        ) : undefined,
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            String(record[dataIndex])
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible && searchInput.current) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex as any ? (
                <span>
          {text}
        </span>
            ) : (
                text
            ),
    });
    const columns: TableColumnsType<DataType> = [
        // {title: t('Bank Account Id'), dataIndex: 'bankAccountId', key: 'bankAccountId', sorter: sortingEnabled ?  (a, b) => a.bankAccountId - b.bankAccountId : undefined, ellipsis: true, ...getColumnSearchProps('bankAccountId')},
        {title: t('Bank Account'), dataIndex: 'bankAccountCode', key: 'bankAccountCode', fixed: 'left', sorter: sortingEnabled ? (a, b) => a.bankAccountCode.localeCompare(b.bankAccountCode) : undefined, ellipsis: true, ...getColumnSearchProps('bankAccountCode')},
        // {title: t('Module Type'), dataIndex: 'moduleType', key: 'moduleType', sorter: sortingEnabled ? (a, b) => a.moduleType.localeCompare(b.moduleType) : undefined, ellipsis: true, ...getColumnSearchProps('moduleType')},
        {
            title: t('Bank'),
            key: 'bankGrouping',
            children: [
                {title: t('Bank'), dataIndex: 'bank', key: 'bank', sorter: sortingEnabled ? (a, b) => a.bank.localeCompare(b.bank) : undefined, ellipsis: true, ...getColumnSearchProps('bank')},
                {title: t('Account Number'), dataIndex: 'bankAccountNumber', key: 'bankAccountNumber', sorter: sortingEnabled ? (a, b) => a.bankAccountNumber.localeCompare(b.bankAccountNumber) : undefined, ellipsis: true, ...getColumnSearchProps('bankAccountNumber')},
                {title: t('Account Name'), dataIndex: 'bankAccountname', key: 'bankAccountname', sorter: sortingEnabled ? (a, b) => a.bankAccountName.localeCompare(b.bankAccountName) : undefined, ellipsis: true, ...getColumnSearchProps('bankAccountName')},
            ],
        },
        {
            title: t('Status'),
            key: 'statusGrouping',
            children:[
                {title: t('Limit'), dataIndex: 'isLimit', key: 'isLimit', sorter: sortingEnabled ? (a, b) => (a.isLimit === b.isLimit) ? 0 : a.isLimit ? -1 : 10 : undefined, ellipsis: true, ...getColumnSearchProps('isLimit'),render: (text, record) => (record.isLimit ? t('Yes') : t('No'))},
                {title: t('Premium'), dataIndex: 'isPremium', key: 'isPremium', sorter: sortingEnabled ? (a, b) => (a.isPremium === b.isPremium) ? 0 : a.isPremium ? -1 : 10 : undefined, ellipsis: true, ...getColumnSearchProps('isPremium'),render: (text, record) => (record.isPremium ? t('Yes') : t('No'))},
                {title: t('Blocked'), dataIndex: 'isBlocked', key: 'isBlocked', sorter: sortingEnabled ? (a, b) => (a.isBlocked === b.isBlocked) ? 0 : a.isBlocked ? -1 : 10 : undefined, ellipsis: true, ...getColumnSearchProps('isBlocked'),render: (text, record) => (record.isBlocked ? t('Yes') : t('No'))},
                {title: t('Active'), dataIndex: 'isActive', key: 'isActive', sorter: sortingEnabled ? (a, b) => (a.isActive === b.isActive) ? 0 : a.isActive ? -1 : 10 : undefined, ellipsis: true, ...getColumnSearchProps('isActive'),render: (text, record) => (record.isActive ? t('Yes') : t('No'))},
            ]
        },
        {title: t('Seq'), dataIndex: 'seq', key: 'seq', sorter: sortingEnabled ?  (a, b) => a.seq - b.seq : undefined, ellipsis: true, ...getColumnSearchProps('seq')},
        {
            title: t('Phone Information'),
            key: 'phoneInformationGrouping',
            children: [
                {
                    title: t('Number'),
                    dataIndex: 'phoneNumber',
                    key: 'phoneNumber',
                    sorter: sortingEnabled ? (a, b) => a.phoneNumber.localeCompare(b.phoneNumber) : undefined,
                    ellipsis: true, ...getColumnSearchProps('phoneNumber')
                },
                {
                    title: t('Expired Date'),
                    dataIndex: 'phoneExpiredDate',
                    key: 'phoneExpiredDate',
                    sorter: sortingEnabled ? (a, b) => a.phoneExpiredDate.localeCompare(b.phoneExpiredDate) : undefined,
                    ellipsis: true, ...getColumnSearchProps('phoneExpiredDate')
                },
                {
                    title: t('Balance'),
                    dataIndex: 'phoneRemainPulsa',
                    key: 'phoneRemainPulsa',
                    sorter: sortingEnabled ? (a, b) => a.phoneRemainPulsa - b.phoneRemainPulsa : undefined,
                    ellipsis: true, ...getColumnSearchProps('phoneRemainPulsa')
                },
            ]
        },
        {
            title: t('Transaction Banking'),
            key: 'transactionBanking',
            children: [
                {
                    title: t('Internet Banking'),
                    children: [
                        {
                            title: t('User'),
                            dataIndex: 'userInternetBanking',
                            key: 'userInternetBanking',
                            sorter: sortingEnabled ? (a, b) => a.userInternetBanking.localeCompare(b.userInternetBanking) : undefined,
                            ellipsis: true, ...getColumnSearchProps('userInternetBanking')
                        },
                        {
                            title: t('Password'),
                            dataIndex: 'pwdInternetBanking',
                            key: 'pwdInternetBanking',
                            sorter: sortingEnabled ? (a, b) => a.pwdInternetBanking.localeCompare(b.pwdInternetBanking) : undefined,
                            ellipsis: true, ...getColumnSearchProps('pwdInternetBanking')
                        },
                    ]
                },
                {
                    title: t('Mobile Banking'),
                    children: [
                        {
                            title: t('User '),
                            dataIndex: 'userMobileBanking',
                            key: 'userMobileBanking',
                            sorter: sortingEnabled ? (a, b) => a.userMobileBanking.localeCompare(b.userMobileBanking) : undefined,
                            ellipsis: true, ...getColumnSearchProps('userMobileBanking')
                        },
                        {
                            title: t('Password'),
                            dataIndex: 'pwdMobileBanking',
                            key: 'pwdMobileBanking',
                            sorter: sortingEnabled ? (a, b) => a.pwdMobileBanking.localeCompare(b.pwdMobileBanking) : undefined,
                            ellipsis: true, ...getColumnSearchProps('pwdMobileBanking')
                        },
                    ]
                },
                {title: t('Pin'), dataIndex: 'pinTransactionBanking', key: 'pinTransactionBanking', sorter: sortingEnabled ? (a, b) => a.pinTransactionBanking.localeCompare(b.pinTransactionBanking) : undefined, ellipsis: true, ...getColumnSearchProps('pinTransactionBanking')},
            ]
        },
        {
            title: t('Use Information'),
            key: 'userInformationGrouping',
            children: [
                {
                    title: t('In Use'),
                    dataIndex: 'inUse',
                    key: 'inUse',
                    sorter: sortingEnabled ? (a, b) => (a.inUse === b.inUse) ? 0 : a.inUse ? -1 : 10 : undefined,
                    ellipsis: true, ...getColumnSearchProps('inUse'),
                    render: (text, record) => (record.inUse ? t('Yes') : t('No'))
                },
                {
                    title: t('Use By'),
                    dataIndex: 'useBy',
                    key: 'useBy',
                    sorter: sortingEnabled ? (a, b) => a.useBy.localeCompare(b.useBy) : undefined,
                    ellipsis: true, ...getColumnSearchProps('useBy')
                },
                {
                    title: t('Use Date'),
                    dataIndex: 'useDate',
                    key: 'useDate',
                    sorter: sortingEnabled ? (a, b) => a.useDate.localeCompare(b.useDate) : undefined,
                    ellipsis: true, ...getColumnSearchProps('useDate')
                },
            ]
        },
        {title: t('Descriptions'), dataIndex: 'description', key: 'description', sorter: sortingEnabled ? (a, b) => a.description.localeCompare(b.description) : undefined, ellipsis: true, ...getColumnSearchProps('description')},
        {title: t('Created By'), dataIndex: 'createdBy', key: 'createdBy', sorter: sortingEnabled ? (a, b) => a.createdBy.localeCompare(b.createdBy) : undefined, ellipsis: true, ...getColumnSearchProps('createdBy')},
        {title: t('Created Date'), dataIndex: 'createdDate', key: 'createdDate', sorter: sortingEnabled ? (a, b) => a.createdDate.localeCompare(b.createdDate) : undefined, ellipsis: true, ...getColumnSearchProps('createdDate')},
        {title: t('Modified By'), dataIndex: 'modifiedBy', key: 'modifiedBy', sorter: sortingEnabled ? (a, b) => a.modifiedBy.localeCompare(b.modifiedBy) : undefined, ellipsis: true, ...getColumnSearchProps('modifiedBy')},
        {title: t('Modified Date'), dataIndex: 'modifiedDate', key: 'modifiedDate', sorter: sortingEnabled ? (a, b) => a.modifiedDate.localeCompare(b.modifiedDate) : undefined, ellipsis: true, ...getColumnSearchProps('modifiedDate')},
        {
            title: 'Actions',
            dataIndex: 'actions',
            width: 100,
            key: 'actions',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">
                    {!record.inUse ?
                        <Tooltip title={t("Lock Use")}>
                            <Button onClick={() => handleLockUse(record.bankAccountId, true)} shape="circle" size={"small"}><LockOutlined /></Button>
                        </Tooltip>
                        :
                        <Tooltip title={t("Unlock Use")}>
                            <Button onClick={() => handleLockUse(record.bankAccountId, false)} shape="circle" size={"small"}><UnlockOutlined /></Button>
                        </Tooltip>
                    }
                    <Tooltip title={t("Edit Record")}>
                        <Button onClick={() => handleEdit(record.bankAccountId)} shape="circle" size={"small"}><EditOutlined /></Button>
                    </Tooltip>
                    <Tooltip title={t("Delete Record")}>
                        <Button onClick={() => handleDelete(record.bankAccountId, record.bankAccountCode)} shape="circle" type='primary' danger size={"small"}><DeleteOutlined /></Button>
                    </Tooltip>
                </Space>
            ),
        },
    ];
    const handleEdit = (bankAccountId:number) => {
        // Logika untuk meng-handle edit
        if(CheckAcess('BankAccount.View')) navigate(`/master/bank_account_dpwd/${bankAccountId}`);
    };
    const handleNew = () => {
        // Logika untuk meng-handle edit
        if(CheckAcess('BankAccount.New')) navigate('/master/bank_account_dpwd/new');
    };
    const handleLockUse = async (bankAccountId:number, inUse:boolean) => {
        try {
            setTableLoading(true);
            const result = await SetUseBankAccount(moduleType, bankAccountId, inUse);
            if(result.status === StatusCode.Code200_OK && result.success) {
                const newData = dataSource.map((item) => {
                    if (item.bankAccountId === bankAccountId) {
                        return { ...item, inUse: (inUse ? true : false) };
                    }
                    return item;
                });
                console.log(newData);
                setDataSource(newData);
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setTableLoading(false);
        }
    };

    const handleDelete = (bankAccountId:number, bankAccountCode:string) => {
        // Logika untuk meng-handle delete
        if(!CheckAcess('BankAccount.Delete')) return;
        Modal.confirm({
            type: 'warning',
            title: t('Warning'),
            content: t('Are you sure want to delete', {field:bankAccountCode}),
            cancelText: t('Cancel'),
            onOk() {
                DeleteData(bankAccountId);
            },
            onCancel() {
                message.open({type: 'info', content: t('Cancel Delete')});
            },
        });
    };

    const ReloadList = async () => {
        try {
            setTableLoading(true);
            await ReloadStyle();
            const result = await GetListBankAccount(moduleType);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                setDataSource(result.data);
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setTableLoading(false);
        }
    };
    const HandleDeleteRow = (bankAccountId:number) => {
        const newData = dataSource.filter((item) => item.bankAccountId !== bankAccountId);
        setDataSource(newData);
    };
    const DeleteData = async (bankAccountId:number) => {
        try {
            setTableLoading(true);
            const result = await DeleteBankAccount(moduleType,bankAccountId);
            if(result.status === StatusCode.Code200_OK && result.success && result.message !== undefined) {
                setDataSource(result.data);
                HandleDeleteRow(bankAccountId);
                message.open({type: 'success', content: result.message});
            }

        } catch (error:any){
            console.log('catch',error);
        }
        finally {
            setTableLoading(false);
        }
    };

    useEffect(() => {
        ReloadList();
    }, []);


    const defaultCheckedList = columns.map((item) => item.key as string).filter((key) => !excludedKeys.includes(key));
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
    const newColumns = columns.map((item) => ({
        ...item,
        hidden: !checkedList.includes(item.key as string),
    }));
    const SaveStyle = async () => {
        try {
            const result = await PostStyle(styleName, checkedList);
            if(result.status === StatusCode.Code200_OK && result.success && result.data !== undefined) {
                message.open({type: 'success', content: result.message});
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
        }
    }
    const ChangedCheckedList = (value:string[]) => {
        setCheckedList(value);
    }
    const ReloadStyle = async () => {
        try {
            const result = await GetStyle(styleName);
            if(result.status === StatusCode.Code200_OK && result.success && result.data.length !> 0) {
                setCheckedList(result.data);
            }
        } catch (error:any){
            console.log('catch',error);
        }
        finally {
        }
    }
    const rowClassName = (record: DataType) => {
        return (record.isPremium ? 'is-premium ' : '') + (record.isLimit ? 'is-limit ' : '') + (record.isActive ? '' : 'is-not-active ') + (record.isBlocked ?  'is-blocked ' : '');
    };
    // useEffect(() => {
    //     ReloadStyle();
    // }, [columns]);
    return (
    <div>
        <Helmet>
        <title>Bank Account| {AppName}</title>
        </Helmet>
            <Flex vertical gap="middle">
                <PageHeader title="Bank" breadcrumbs={ITEMS_MASTER_BANK_ACCOUNT}/>
                <Row {...stylesContext?.rowProps}>
                    <Col xs={24} lg={24}>
                        <Card>
                            <Flex vertical gap="small">
                                <Row>
                                    <Col xs={24} lg={24}>
                                        <FlexCustom justifyContent="end">
                                            <Tooltip title={t("New Record")}>
                                                <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={handleNew} />
                                            </Tooltip>
                                            <Tooltip title={t("Columns")}>
                                                <Button type="primary" shape="circle" icon={<ProjectOutlined />} onClick={()=>setOpenModalColumn(true)} />
                                            </Tooltip>
                                            <Tooltip title={t( (sortingEnabled ? "Disable" : "Enable") + " Sort")}>
                                                <Button type="primary" shape="circle" icon={<SortAscendingOutlined />} onClick={()=>setSortingEnabled(!sortingEnabled)} />
                                            </Tooltip>
                                            <Tooltip title={t( (sortingEnabled ? "Disable" : "Enable") + " Filter")}>
                                                <Button type="primary" shape="circle" icon={<FilterOutlined />} onClick={()=>setSearchingEnabled(!searchingEnabled)} />
                                            </Tooltip>
                                        </FlexCustom>
                                    </Col>
                                </Row>
                                <Table rowKey="bankAccountId"
                                    bordered
                                    columns={newColumns}
                                    dataSource={dataSource}
                                    loading={tableLoading}
                                    scroll={{ x: true }}
                                    rowClassName={rowClassName}
                                    size="small" />
                                <Modal
                                    title={<p>List Columns</p>}
                                    footer={
                                        <Button type="primary" onClick={SaveStyle}>
                                            Save Template
                                        </Button>
                                    }
                                    open={openModalColumn}
                                    onCancel={()=>setOpenModalColumn(false)}
                                >
                                    <Checkbox.Group
                                        value={checkedList}
                                        onChange={(value) => {
                                            ChangedCheckedList(value as string[]);
                                        }}
                                    >
                                        <Row>
                                            {columns.map(option => (
                                                <Col xs={12} lg={8} key={option.title as string}>
                                                    <Checkbox value={option.key} disabled={option.title==='Actions'}>{option.title as string}</Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Modal>
                            </Flex>
                        </Card>
                    </Col>
                </Row>
            </Flex>
    </div>
  );
};

export default BankAccount;
