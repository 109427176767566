import { Result, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { red } from '@ant-design/colors';
import {BackBtn} from "../../components/BackBtn/BackBtn";
import {RefreshBtn} from "../../components/RefreshBtn/RefreshBtn";
import {useTranslation} from "react-i18next";

const { Paragraph, Text } = Typography;

export const Error400Page = () => {
  const { t} = useTranslation();
  return (
    <Result
      status="error"
      title="400"
      subTitle={t("Bad request. The request could not be understood by the server due to malformed syntax. The client should not repeat the request without modifications")}
      extra={[<BackBtn type="primary" />, <RefreshBtn />]}
    >
    </Result>
  );
};
