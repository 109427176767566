import React from "react";
import {
    BankOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    LockOutlined,
    MenuUnfoldOutlined, ProfileOutlined,
    SettingOutlined, UsergroupDeleteOutlined,
} from "@ant-design/icons";
import {AntdIconProps} from "@ant-design/icons/es/components/AntdIcon";
import {PATH_HOME, PATH_MASTER, PATH_SETTING, PATH_TRANSACTION, PATH_UTILITY} from "./RoutePathList";
import {LiaMoneyCheckAltSolid, LiaNewspaperSolid} from "react-icons/lia";

export interface BreadcrumbType {
    icon: React.ElementType,
    name: string,
    link: boolean,
    path: string
}


export const ITEMS_HOME:BreadcrumbType[] = [
  {icon:HomeOutlined, name: 'Home', link:true, path: PATH_HOME.home}
];
export const ITEMS_ABOUT:BreadcrumbType[] = [
    ...ITEMS_HOME,
    {icon:InfoCircleOutlined, name: 'About', link:true, path: PATH_HOME.about}
];


export const ITEMS_TRANSACTION:BreadcrumbType[] =[
    ...ITEMS_HOME,
    {icon:ProfileOutlined , name: 'Transaction', link:false, path: PATH_TRANSACTION.path},
];
export const ITEMS_TRANSACTION_DPWD:BreadcrumbType[] =[
    ...ITEMS_TRANSACTION,
    {icon:LiaMoneyCheckAltSolid, name: 'DPWD', link:true, path: PATH_TRANSACTION.dpwd},
];
export const ITEMS_TRANSACTION_CAHSFLOW:BreadcrumbType[] =[
    ...ITEMS_TRANSACTION,
    {icon:LiaMoneyCheckAltSolid, name: 'Bank', link:true, path: PATH_TRANSACTION.cashflow},
];
export const ITEMS_TRANSACTION_COINFLOW:BreadcrumbType[] =[
    ...ITEMS_TRANSACTION,
    {icon:LiaMoneyCheckAltSolid, name: 'Coin', link:true, path: PATH_TRANSACTION.coinflow},
];

export const ITEMS_MASTER:BreadcrumbType[] = [
    ...ITEMS_HOME,
    {icon:MenuUnfoldOutlined, name: 'Master', link:false, path: PATH_MASTER.path}
];
export const ITEMS_MASTER_BANK:BreadcrumbType[] =[
    ...ITEMS_MASTER,
    {icon:BankOutlined, name: 'Bank', link:true, path: PATH_MASTER.bank},
];
export const ITEMS_MASTER_BANK_ACCOUNT:BreadcrumbType[] =[
    ...ITEMS_MASTER,
    {icon:BankOutlined, name: 'Bank Account DPWD', link:true, path: PATH_MASTER.bank_account},
];
export const ITEMS_MASTER_TRANSTYPE:BreadcrumbType[] =[
    ...ITEMS_MASTER,
    {icon:BankOutlined, name: 'Trans Type', link:true, path: PATH_MASTER.transType},
];
export const ITEMS_MASTER_MEMBER:BreadcrumbType[] =[
    ...ITEMS_MASTER,
    {icon:BankOutlined, name: 'Member', link:true, path: PATH_MASTER.member},
];
export const ITEMS_UTILITY:BreadcrumbType[] =[
    ...ITEMS_HOME,
    {icon:ProfileOutlined , name: 'Utility', link:false, path: PATH_UTILITY.path},
];
export const ITEMS_UTILITY_CLOSING:BreadcrumbType[] =[
    ...ITEMS_UTILITY,
    {icon:LiaMoneyCheckAltSolid, name: 'Closing', link:true, path: PATH_UTILITY.closing},
];
export const ITEMS_UTILITY_TODAY_SUMMARY:BreadcrumbType[] =[
    ...ITEMS_UTILITY,
    {icon:LiaMoneyCheckAltSolid, name: 'Today Summary', link:true, path: PATH_UTILITY.today},
];


export const ITEMS_SETTING:BreadcrumbType[] = [
  ...ITEMS_HOME,
  {icon:SettingOutlined, name: 'Setting', link:false, path: PATH_SETTING.path}
];
export const ITEMS_SETTING_ROLE:BreadcrumbType[] =[
    ...ITEMS_SETTING,
    {icon:LockOutlined , name: 'Role', link:true, path: PATH_SETTING.role},
];
export const ITEMS_SETTING_USER:BreadcrumbType[] =[
  ...ITEMS_SETTING,
  {icon:UsergroupDeleteOutlined, name: 'User', link:true, path: PATH_SETTING.user},
];



